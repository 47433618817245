<template>
  <section id="meeting" class="meeting">
    <div class="container">
      <div class="section-head">
        <h2 class="section-head__ttl">
          Призначити зустріч з Project менеджером
        </h2>
      </div>
      <div class="meeting__content">
        <div class="meeting__img">
          <div
              class="meeting__img-helper"
              v-webp:bgs="[imageBgWebp, imageBg]"
          ></div>
          <div class="name">
            <span>Нечипоренко Максим</span>
            <p>Проджект менеджер “Octopus”</p>
          </div>
        </div>
        <div class="meeting__form">
          <form class="form ajax-form" @submit.prevent="submit">
            <div class="form__head">
              <span>Хочете обговорити свій проект?</span>
              Залишайте свої контакти або зв'яжіться з нами за допомогою одного з месенджерів
            </div>
            <div class="input-wrap">
              <input
                  id="meeting-name"
                  v-model="formData.name"
                  name="name"
                  type="text"
                  autocomplete="name"
                  placeholder="Ваше Ім’я та Прізвище"
                  required
              >
              <masked-input
                  v-model="formData.phone"
                  id="meeting-phone"
                  mask="\+38 011 111 11 11"
                  placeholder="+38 093 192 70 57"
                  type="tel"
                  name="phone"
                  required
              ></masked-input>
              <button type="submit" class="btn btn_green">Призначити зустріч</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SubmitForm from '@/mixins/SubmitForm'

export default {
  name: 'Meeting',
  mixins: [SubmitForm],
  data() {
    return {
      formData: {
        subject: 'Призначити зустріч',
        phone: '',
        name: '',
      },
      imageBg: require('@/assets/image/meeting-img-1.png'),
      imageBgWebp: require('@/assets/image/meeting-img-1.webp'),
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.meeting {
  position: relative;
  padding: 112px 0 140px;

  @media (max-width: $max-md) {
    padding: 90px 0 60px;
  }

  @media (max-width: $max-sm) {
    padding: 40px 0 30px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 132px;
    padding-left: calc((100% - (648px + 760px + 60px)) / 2);
    padding-right: calc((100% - (648px + 760px + 60px)) / 2);

    @media (max-width: 1699px) {
      margin-top: 110px;
    }

    @media (max-width: $max-xxl) {
      margin-top: 90px;
      padding-left: calc((100% - (550px + 600px)) / 2);
      padding-right: calc((100% - (550px + 600px)) / 2);
    }

    @media (max-width: $max-xl) {
      margin-top: 70px;
      padding: 0;
    }

    @media (max-width: $max-lg) {
      flex-direction: column;
    }
  }

  &__img {
    position: relative;
    width: 648px;

    @media (max-width: $max-xxl) {
      width: 550px;
    }

    @media (max-width: $max-xl) {
      width: 450px;
    }

    @media (max-width: $max-lg) {
      width: 100%;
      max-width: 450px;
    }

    @media (max-width: $max-sm) {
      max-width: 320px;
    }

    @media (max-width: $max-xs) {
      max-width: 255px;
    }

    &-helper {
      position: relative;
      width: 100%;
      height: 610px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: $max-xxl) {
        height: 520px;
      }

      @media (max-width: $max-xl) {
        height: 425px;
      }

      @media (max-width: $max-sm) {
        height: 300px;
      }

      @media (max-width: $max-xs) {
        height: 240px;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 25%;
        bottom: -1px;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(18, 23, 34, 0) 0%, #121722 100%);
      }
    }

    .name {
      position: absolute;
      width: 100%;
      bottom: -50px;
      left: 0;
      font-size: 18px;
      font-weight: 500;

      @media (max-width: $max-md) {
        font-size: 16px;
      }

      @media (max-width: $max-sm) {
        bottom: -30px;
        font-size: 14px;
      }

      @media (max-width: $max-xs) {
        width: 290px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
      }

      span {
        font-size: 26px;
        font-weight: 600;

        @media (max-width: $max-md) {
          font-size: 24px;
        }

        @media (max-width: $max-sm) {
          font-size: 22px;
        }

        @media (max-width: $max-xs) {
          font-size: 18px;
        }
      }

      p {
        margin-top: 10px;

        @media (max-width: $max-xl) {
          margin-top: 8px;
        }

        @media (max-width: $max-sm) {
          margin-top: 5px;
        }

        @media (max-width: $max-xs) {
          margin-top: 3px;
        }
      }
    }
  }

  &__form {
    width: 700px;
    margin-top: 50px;
    padding: 0 100px;

    @media (max-width: 1699px) {
      width: 600px;
      padding: 0 80px;
    }

    @media (max-width: $max-xxl) {
      width: 500px;
      padding: 0 30px;
    }

    @media (max-width: $max-xl) {
      width: 400px;
    }

    @media (max-width: $max-lg) {
      width: 100%;
      max-width: 450px;
      margin-top: 100px;
    }

    @media (max-width: $max-xs) {
      margin-top: 80px;
      padding: 0;
    }

    .form {
      text-align: left;

      @media (max-width: $max-lg) {
        text-align: center;
      }

      &__head {
        margin-bottom: 48px;
        font-size: 21px;
        font-weight: 500;

        @media (max-width: $max-xl) {
          margin-bottom: 35px;
          font-size: 20px;
        }

        @media (max-width: $max-md) {
          font-size: 18px;
        }

        @media (max-width: $max-sm) {
          margin-bottom: 25px;
          font-size: 16px;
        }

        @media (max-width: $max-xs) {
          margin-bottom: 20px;
          font-size: 14px;
        }

        span {
          display: block;
          margin-bottom: 16px;
          font-weight: 600;
          font-size: 24px;

          @media (max-width: $max-md) {
            font-size: 20px;
          }

          @media (max-width: $max-sm) {
            font-size: 18px;
          }
        }
      }

      .input-wrap {
        display: flex;
        flex-direction: column;
        max-width: 400px;

        @media (max-width: $max-sm) {
          max-width: 264px;
          margin-left: auto;
          margin-right: auto;
        }

        input {
          height: 56px;
          margin-bottom: 32px;
          padding: 0 20px;
          font-size: 24px;
          line-height: 73px;

          @media (max-width: $max-xl) {
            font-size: 20px;
            line-height: 60px;
          }

          @media (max-width: $max-lg) {
            margin-bottom: 25px;
            text-align: center;
          }

          @media (max-width: $max-md) {
            height: 50px;
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 50px;
          }

          @media (max-width: $max-sm) {
            height: 40px;
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 40px;
          }

          @media (max-width: $max-xs) {
            height: 35px;
            margin-bottom: 12px;
            font-size: 14px;
            line-height: 35px;
          }
        }

        input#phone {
          max-width: 290px;
          margin-bottom: 0;

          @media (max-width: $max-lg) {
            max-width: 100%;
          }
        }

        *::-webkit-input-placeholder {
          font-size: 24px;
          color: #bbbbbb;
          line-height: 73px;
          letter-spacing: 1px;

          @media (max-width: $max-xl) {
            font-size: 20px;
            line-height: 60px;
            letter-spacing: normal;
          }

          @media (max-width: $max-lg) {
            text-align: center;
          }

          @media (max-width: $max-md) {
            font-size: 18px;
            line-height: 50px;
          }

          @media (max-width: $max-sm) {
            font-size: 16px;
            line-height: 40px;
          }

          @media (max-width: $max-xs) {
            font-size: 14px;
            line-height: 35px;
          }
        }

        *:-moz-placeholder {
          font-size: 24px;
          color: #bbbbbb;
          line-height: 73px;
          letter-spacing: 1px;

          @media (max-width: $max-xl) {
            font-size: 20px;
            line-height: 60px;
            letter-spacing: normal;
          }

          @media (max-width: $max-lg) {
            text-align: center;
          }

          @media (max-width: $max-md) {
            font-size: 18px;
            line-height: 50px;
          }

          @media (max-width: $max-sm) {
            font-size: 16px;
            line-height: 40px;
          }

          @media (max-width: $max-xs) {
            font-size: 14px;
            line-height: 35px;
          }
        }

        *::-moz-placeholder {
          font-size: 24px;
          color: #bbbbbb;
          line-height: 73px;
          letter-spacing: 1px;

          @media (max-width: $max-xl) {
            font-size: 20px;
            line-height: 60px;
            letter-spacing: normal;
          }

          @media (max-width: $max-lg) {
            text-align: center;
          }

          @media (max-width: $max-md) {
            font-size: 18px;
            line-height: 50px;
          }

          @media (max-width: $max-sm) {
            font-size: 16px;
            line-height: 40px;
          }

          @media (max-width: $max-xs) {
            font-size: 14px;
            line-height: 35px;
          }
        }

        *:-ms-input-placeholder {
          font-size: 24px;
          color: #bbbbbb;
          line-height: 73px;
          letter-spacing: 1px;

          @media (max-width: $max-xl) {
            font-size: 20px;
            line-height: 60px;
            letter-spacing: normal;
          }

          @media (max-width: $max-lg) {
            text-align: center;
          }

          @media (max-width: $max-md) {
            font-size: 18px;
            line-height: 50px;
          }

          @media (max-width: $max-sm) {
            font-size: 16px;
            line-height: 40px;
          }

          @media (max-width: $max-xs) {
            font-size: 14px;
            line-height: 35px;
          }
        }

        label {
          margin-bottom: 12px;
          font-size: 16px;
          color: #9e9e9e;
          font-weight: 500;

          @media (max-width: $max-xl) {
            margin-bottom: 10px;
            font-size: 15px;
          }

          @media (max-width: $max-sm) {
            font-size: 12px;
          }

          @media (max-width: $max-xs) {
            margin-bottom: 7px;
            font-size: 9px;
          }
        }

        .btn {
          height: 64px;
          margin-top: 16px;
          font-size: 21px;
          border-radius: 36.5px;

          @media (max-width: $max-xl) {
            width: 100%;
            max-width: 300px;
            margin-top: 40px;
            font-size: 20px;
          }

          @media (max-width: $max-lg) {
            margin: 20px auto 0;
          }

          @media (max-width: $max-md) {
            height: 52px;
            margin: 15px auto 0;
            font-size: 17px;
          }

          @media (max-width: $max-sm) {
            max-width: 220px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
</style>
