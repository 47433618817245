<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__content">
        &copy; Всі права захищено {{ new Date().getFullYear() }}
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.footer {
  padding: 40px 0;

  @media (max-width: $max-sm) {
    padding: 30px 0;
  }

  @media (max-width: $max-xs) {
    padding: 20px 0;
  }

  &__content {
    font-size: 18px;
    font-weight: 500;
    opacity: .8;
    text-align: center;

    @media (max-width: $max-md) {
      font-size: 16px;
    }

    @media (max-width: $max-sm) {
      font-size: 14px;
    }

    @media (max-width: $max-xs) {
      font-size: 10px;
    }
  }
}
</style>
