<template>
  <div>
    <section id="portfolio" class="portfolio">
      <div class="container">
        <div class="section-head">
          <h2 class="section-head__ttl">
            Портфоліо
          </h2>
        </div>
        <div class="portfolio__select-wrap">
          <div class="limit limit--price">
            <div class="limit__txt">
              Бюджет до:
            </div>

            <template v-if="innerWidth > 991">
              <div class="limit__check">
                <div
                    v-for="item in priceSelectOptions"
                    :key="item.id"
                    :class="['custom-checkbox', { active: isSelected(item) }]"
                    @click="priceChangeHandler(item)"
                >
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </template>
            <template v-else>
              <v-select
                  :value="priceSelectedItems[0]"
                  :class="['select-1']"
                  :options="priceSelectOptions"
                  label="name"
                  placeholder="Обрати"
                  :clearable="false"
                  :filterable="false"
                  :searchable="false"
                  @input="priceSelectedItems = [$event]"
              ></v-select>
            </template>

          </div>
          <div class="limit limit--type">
            <div class="limit__txt">
              Призначення:
            </div>
            <v-select
                v-model="typeSelectedItems"
                :class="['select-2', { multiple: typeSelectedItems.length > 1 }]"
                :options="typeSelectOptions"
                label="name"
                :filterable="false"
                :searchable="false"
                multiple
                :placeholder="innerWidth > 1200 ? 'Обрати 1 або декілька' : 'Обрати'"
            ></v-select>
          </div>

          <div class="reset">
            <div
                v-if="priceSelectedItems.length || typeSelectedItems.length || currentPage > 1"
                class="reset-btn"
                @click="reset"
            >
              <img :src="require(`@/assets/image/close.svg`)" alt="">
            </div>
          </div>
        </div>
        <div class="portfolio__content">
          <template v-if="showedItems.length">
            <div
                v-for="(item, idx) in showedItems"
                :key="item.id"
                :class="[
                  'portfolio__item',
                  { 'last-item': showedItems.length > 1 && showedItems.length === idx + 1 && currentPage < totalPages }
                ]"
            >
              <div class="portfolio__item-helper">
                <template v-if="Array.isArray(item.imageUrl)">
                  <img
                      v-webp:src="require(`@/assets/image/portfolio/${item.imageUrl[0]}`)"
                      :src="require(`@/assets/image/portfolio/${item.imageUrl[1]}`)"
                      :alt="item.title"
                      class="img"
                  >
                </template>
                <template v-else>
                  <img
                      :src="require(`@/assets/image/portfolio/${item.imageUrl}`)"
                      :alt="item.title"
                      class="img"
                  >
                </template>
                <div class="order">
                  <div class="order-overlay">
                    <div class="order__descr">{{ item.shortDescription }}</div>
                    <div
                        class="order__info"
                        @click="showPortfolioDetailDialogClickHandler(item)"
                    ></div>
                    <div class="order__price">
                      <div class="order__price-budget">Бюджет: <span>{{ item.price }}</span></div>
                      <div class="order__price-term">Термін: <span>{{ item.term }}</span></div>
                    </div>
                    <button
                        class="btn btn_h_60 btn_green"
                        @click="showPortfolioCallbackDialogClickHandler(item)"
                    >
                      Замовити
                    </button>
                    <span
                        class="video-link"
                        @click="showPortfolioVideoClickHandler(item)"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div
                v-if="currentPage !== totalPages"
                class="show-more"
            >
              <div
                  class="show-more__bg"
                  v-webp:bgs="[
                    require('@/assets/image/show-more-bg.webp'),
                    require('@/assets/image/show-more-bg.jpg'),
                  ]"
              ></div>
              <div
                  class="show-more__btn"
                  @click="showMoreClickHandler"
              >
                Дивитись ще
              </div>
            </div>
          </template>
          <template v-else>
            Змініть критерії пошуку
          </template>
        </div>
      </div>
    </section>

    <YoutubeDialog
        :item="videoDialog"
        :key="key"
    />
    <PortfolioDetailDialog
        :item="detailDialog"
        @show-youtube-dialog="showYoutubeDialog($event)"
        @show-callback-dialog="showCallbackDialog($event)"
    />
    <PortfolioCallbackDialog :item="callbackDialog" />
  </div>
</template>

<script>
import YoutubeDialog from '@/components/YoutubeDialog'
import PortfolioDetailDialog from '@/components/PortfolioDetailDialog'
import PortfolioCallbackDialog from '@/components/PortfolioCallbackDialog'

import { type } from '@/db/type'
import { price } from '@/db/price'
import { portfolio } from '@/db/portfolio'

export default {
  name: 'Portfolio',
  components: { YoutubeDialog, PortfolioDetailDialog, PortfolioCallbackDialog },
  props: {
    innerWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      detailFancy: null,
      videoDialog: {},
      detailDialog: {},
      callbackDialog: {},
      typeSelectedItems: [],
      priceSelectedItems: [],
      items: portfolio,
      typeSelectOptions: type,
      priceSelectOptions: price,
      currentPage: 1,
      key: 1,
    }
  },
  computed: {
    perPage() {
      return this.innerWidth > 991 ? 6 : 4
    },
    filteredItems() {
      let items = this.items

      if (this.priceSelectedItems.length) {
        items = this.items.filter(item => this.priceSelectedItems.filter(el => el.value === item.priceValue).length)
      }

      if (this.typeSelectedItems.length) {
        const filters = this.typeSelectedItems.map(item => item.type)

        items = items.filter(item => {
          for (let i = 0; i < item.types.length; i++) {
            if (filters.includes(item.types[i])) return true
          }
        })
      }

      return items
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.perPage)
    },
    showedItems() {
      return this.filteredItems.slice(0, this.perPage * this.currentPage)
    }
  },
  watch: {
    'showedItems.length'() {
      this.updateScroll()
    },
  },
  methods: {
    priceChangeHandler(item) {
      if (this.isSelected(item)) {
        this.priceSelectedItems = this.priceSelectedItems.filter(el => el.id !== item.id)
      } else {
        this.priceSelectedItems = [item]
      }
    },
    isSelected(item) {
      return this.priceSelectedItems.find(el => el.id === item.id)
    },
    showPortfolioDetailDialogClickHandler(item) {
      this.detailDialog = item

      this.detailFancy = window.$fancybox.show(
          [
            {src: '#portfolioDetailDialog', type: 'inline'},
          ],
          {
            autoFocus: false,
            on: {
              shouldClose: () => {
                this.detailDialog = {}
              },
            },
          })
    },
    showPortfolioCallbackDialogClickHandler(item) {
      this.callbackDialog = item

      const fancyInstance = window.$fancybox.show(
          [
            {src: '#portfolioCallbackDialog', type: 'inline'},
          ],
          {
            autoFocus: false,
            on: {
              shouldClose: () => {
                this.callbackDialog = {}
              },
              destroy: () => {
                this.$store.commit('RESET_FANCY_INSTANCE')
              },
            },
          }
      )

      this.$store.commit('SET_FANCY_INSTANCE', fancyInstance)
    },
    showPortfolioVideoClickHandler(item) {
      this.videoDialog = item

      this.detailFancy = window.$fancybox.show(
          [
            {src: '#videoDialog', type: 'inline'},
          ],
          {
            autoFocus: false,
            on: {
              shouldClose: () => {
                this.videoDialog = {}
              },
              destroy: () => {
                this.key++
                this.$store.commit('RESET_FANCY_INSTANCE')
              },
            },
          })
    },
    showCallbackDialog(item) {
      this.detailFancy.close()
      this.showPortfolioCallbackDialogClickHandler(item)
    },
    showYoutubeDialog(item) {
      this.detailFancy.close()
      this.showPortfolioVideoClickHandler(item)
    },
    showMoreClickHandler() {
      this.currentPage++
    },
    reset() {
      this.priceSelectedItems = []
      this.typeSelectedItems = []
      this.currentPage = 1
    },
    updateScroll() {
      this.$emit('update-scroll')
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/portfolio.scss';
</style>
