import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import MaskedInput from "vue-masked-input";
import vSelect from "vue-select";
import VueYoutube from "vue-youtube";
import VueWebpImage from "vue-webp-image";

import "vue-select/dist/vue-select.css";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import "./registerServiceWorker";
import { router } from "@/routes";

Vue.config.productionTip = false;

Vue.component("masked-input", MaskedInput);
Vue.component("v-select", vSelect);

Vue.use(VueWebpImage);
Vue.use(VueMeta);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueYoutube);

const store = new Vuex.Store({
  state: {
    fancyInstance: null
  },
  mutations: {
    SET_FANCY_INSTANCE(state, payload) {
      state.fancyInstance = payload;
    },
    RESET_FANCY_INSTANCE(state) {
      state.fancyInstance = null;
    }
  }
});

window.$fancybox = Fancybox;

new Vue({
  render: h => h(App),
  router,
  store
}).$mount("#app");
