<template>
  <section id="services" class="services">
    <div class="container">
      <div class="section-head">
        <h2 class="section-head__ttl">
          Послуги
        </h2>
      </div>
      <div class="services__content">
        <div v-for="item in items" :key="item.id" class="services__item">
          <div class="services__item-helper">
            <div class="img-wrap">
              <div class="img-wrap-helper">
                <video
                    width="100%"
                    autoplay
                    loop
                    muted
                    :poster="require(`@/assets/image/${item.imageUrl}`)"
                >
                  <source :src="`${baseUrl}video/${item.video}.webm`" type="video/webm">
                  <source :src="`${baseUrl}video/${item.video}.mp4`" type="video/mp4">
                  Your browser doesn't support HTML5 video tag.
                </video>
              </div>
            </div>
            <h3>{{ item.title }}</h3>
          </div>
        </div>
      </div>
      <div id="animationContainer" class="services__bottom">
        <LavaAnimation
            target-id="services-animation"
            :width="innerWidth"
            :height="innerHeight"
        />
        <div>
          <h3>Створення та ведення ютуб каналів</h3>
          <p>
            це безліч можливостей для малого та середнього бізнесу. <br>
            За допомогою YouTube каналу ви:
          </p>
          <ul>
            <li v-for="(item, idx) in list" :key="idx" :class="{ active: idx === currentItem }">
              <span>{{ item }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { service } from '@/db/service'

import LavaAnimation from '@/components/LavaAnimation'

export default {
  name: 'Services',
  components: { LavaAnimation },
  props: {
    innerWidth: {
      type: Number,
      required: true,
    },
    innerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: service,
      baseUrl: process.env.BASE_URL,
      intervalId: null,
      currentItem: 0,
      list: [
          'Привертає увагу потенційний клієнтів',
          'Співпрацюєте з відомими блогерами',
          'Показуєте свою експертність',
          'Створюєте комьюніті однодумців',
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.intervalId = setInterval(() => {
        this.currentItem = this.currentItem + 1 < this.list.length ? this.currentItem + 1 : 0
      }, 1800)
    })
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.services {
  position: relative;
  padding: 112px 15px 0;

  @media (max-width: $max-lg) {
    padding-top: 80px;
  }

  @media (max-width: $max-md) {
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    width: 100%;
    max-width: 1735px;
    margin: 0 auto;
    padding: 0;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 15px);
    margin-top: 90px;

    @media (max-width: $max-xxl) {
      margin-top: 70px;
    }

    @media (max-width: $max-xl) {
      margin-top: 50px;
    }

    @media (max-width: $max-lg) {
      margin-top: 40px;
    }

    @media (max-width: $max-md) {
      //flex-direction: column;
      //align-items: center;
      width: 100%;
      margin-top: 30px;
    }

    @media (max-width: $max-sm) {
      margin-top: 20px;
    }

    @media (max-width: $max-xs) {
      margin-top: 15px;
    }
  }

  &__item {
    width: 33.3333%;
    padding: 0 15px 60px 0;
    text-align: left;

    @media (max-width: $max-md) {
      width: 50%;
      max-width: 400px;
      margin: 0 auto;
      padding: 0 4px 30px 4px;
    }

    @media (max-width: $max-sm) {
      padding: 0 4px 15px 4px;
    }

    .img-wrap {
      width: 100%;

      &-helper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.1433%;

        video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }

    h3 {
      margin-top: 24px;
      font-size: 22px;
      font-weight: 600;

      @media (max-width: $max-lg) {
        margin-top: 20px;
        font-size: 18px;
        text-align: center;
      }

      @media (max-width: $max-md) {
        margin-top: 15px;
        font-size: 16px;
      }

      @media (max-width: $max-sm) {
        margin-top: 10px;
        font-size: 13px;
      }

      @media (max-width: $max-xs) {
        margin-top: 5px;
      }
    }
  }

  &__bottom {
    position: relative;
    padding: 52px 15px 0;
    line-height: 1.2;

    & > div {
      position: relative;
    }

    h3 {
      font-weight: 600;
      font-size: 32px;

      @media (max-width: $max-xl) {
        font-size: 26px;
      }

      @media (max-width: $max-sm) {
        font-size: 22px;
      }

      @media (max-width: $max-xs) {
        font-size: 18px;
      }
    }

    p {
      font-weight: 600;
      font-size: 24px;

      @media (max-width: $max-xl) {
        font-size: 20px;
      }

      @media (max-width: $max-sm) {
        font-size: 16px;
      }

      @media (max-width: $max-xs) {
        font-size: 14px;
      }
    }

    ul {
      margin: 24px 0 0;
      list-style-type: none;
      font-size: 18px;
      font-weight: 500;

      @media (max-width: $max-xl) {
        font-size: 16px;
      }

      @media (max-width: $max-sm) {
        font-size: 14px;
      }

      @media (max-width: $max-xs) {
        font-size: 12px;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 16px;

          @media (max-width: $max-xs) {
            margin-bottom: 8px;
          }
        }

        span {
          position: relative;
          display: inline-block;
          padding: 0 40px;

          @media (max-width: $max-sm) {
            padding: 0 30px;
          }

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            width: 0;
            height: 0;
            border-radius: 50%;
            background: $accent;
            filter: blur(4px);
            transform: translateY(-50%);
            transition: all 0.4s ease-out;

            @media (max-width: $max-sm) {
              filter: blur(3px);
            }
          }

          &::before {
            left: 0;
          }

          &::after {
            right: 0;
          }
        }

        &.active span {
          &::before,
          &::after {
            width: 18px;
            height: 18px;

            @media (max-width: $max-sm) {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
