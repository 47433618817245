<template>
  <section id="algorithm" class="algorithm">
    <LavaAnimation
        target-id="algorithm-animation"
        :width="innerWidth"
        :height="innerHeight"
        :quantity="3"
        color="#59f8e8"
    />
    <div class="container">
      <div class="algorithm__content">
        <div class="section-head">
          <h2 class="section-head__ttl">
            Розвиток ютуб каналу
          </h2>
          <div class="section-head__subttl">
            Створення та ведення YouTube каналу під ключ
          </div>
        </div>
      </div>
      <div class="steps">
        <div
            v-for="(item, idx) in items" :key="item.id"
            :class="['steps__item animate__animated', `${idx % 2 ? 'animate__fadeInLeft' : 'animate__fadeInRight'}` ]"
        >
          <div class="item noselect">
            <div class="item__ttl">{{ item.title }}</div>
            <div class="item__text">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <div class="description">
        Мінімальний місячний пакет коштує <span class="nobr">40 000 грн</span>, <br> і може містити
        різну кількість контенту та реклами, все залежить від Вашої ніші.
      </div>
    </div>
    <div class="form-wrap">
      <div>
        <div>
          Звертайтесь за розрахунком вартості
        </div>
        <form class="callback" @submit.prevent="submit">
          <masked-input
              v-model="formData.phone"
              mask="\+38 011 111 11 11"
              placeholder="+38 093 192 70 57"
              type="tel"
              name="phone"
              required
          ></masked-input>
          <button class="callback__btn" type="submit">
            Замовити
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { youtube } from '@/db/youtube'

import SubmitForm from '@/mixins/SubmitForm'
import LavaAnimation from '@/components/LavaAnimation'

export default {
  name: 'Algorithm',
  mixins: [SubmitForm],
  components: { LavaAnimation },
  props: {
    innerWidth: {
      type: Number,
      required: true,
    },
    innerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: youtube,
      formData: {
        subject: 'Розрахунок вартості',
        phone: '',
      },
    }
  },
  beforeMount() {
    this.selectedItem = this.items[0]
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.algorithm {
  position: relative;
  padding-top: 112px;

  @media (max-width: $max-lg) {
    padding-top: 80px;
  }

  @media (max-width: $max-md) {
    padding-top: 50px;
  }

  .steps {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1440px;
    margin: 48px auto 0;
    overflow: hidden;

    @media (max-width: $max-md) {
      max-width: 560px;
      margin: 30px auto;
    }

    &__item {
      margin: 0 0 48px 0;
      padding: 0 40px;
      color: $black;

      @media (max-width: $max-xxl) {
        font-size: 16px;
      }

      @media (max-width: $max-xl) {
        margin: 0 0 20px 0;
        padding: 0;
        font-size: 14px;
        font-weight: 50;
      }

      @media (max-width: $max-md) {
        margin: 0 0 10px 0;
      }

      .item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 400px;
        min-height: 74px;
        padding: 10px;
        background-color: $white;

        @media (max-width: $max-xxl) {
          min-height: 64px;
        }

        @media (max-width: $max-xl) {
          max-width: 100% !important;
          min-height: 35px;
        }

        //&:not(.selected) {
        //  cursor: pointer;
        //}
        //
        //&:hover,
        //&.selected {
        //  box-shadow: 0 0 20px 2px rgba(255, 255, 255, 0.5);
        //}

        &__ttl {
          margin-bottom: 12px;
          font-size: 18px;
          font-weight: 600;

          @media (max-width: $max-xxl) {
            font-size: 16px;
          }
        }

        &__text {
          font-size: 14px;

          @media (max-width: $max-xxl) {
            font-size: 13px;
          }
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: 33.3333%;

        @media (max-width: $max-xl) {
          width: 100%;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(7),
      &:nth-child(9) {
        .item__ttl::before {
          content: '+';
          position: absolute;
          width: 34px;
          height: 79px;
          top: 50%;
          transform: translateY(-50%);
          right: -55px;
          font-size: 65px;
          color: $white;
          font-weight: 400;

          @media (max-width: $max-xl) {
            display: none;
          }
        }
      }

      &:nth-child(7),
      &:nth-child(9) {
        @media (min-width: $min-xl) {
          display: flex;
          justify-content: flex-end;
        }
      }

      &:nth-child(7),
      &:nth-child(8) {
        width: 50%;

        @media (max-width: $max-xl) {
          width: 100%;
        }

        .item {
          width: 100%;
          max-width: 600px;
        }
      }

      &:nth-child(9),
      &:nth-child(10) {
        width: 50%;

        @media (max-width: $max-xl) {
          width: 100%;
        }

        .item {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }

  .description {
    position: relative;
    margin: 10px 0 36px;
    font-size: 21px;
    font-weight: 500;

    @media (max-width: $max-sm) {
      margin: 10px 0 22px;
      font-size: 16px;
    }

    @media (max-width: $max-xs) {
      font-size: 14px;
    }
  }

  .form-wrap {
    position: relative;
    padding: 30px 15px;

    @media (max-width: $max-md) {
      padding: 30px 5px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(99.07deg, #59F8E8 6.63%, #00B3DB 127.91%);
    }

    & > div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black;

      @media (max-width: $max-xl) {
        flex-direction: column;
      }

      & > div {
        margin-right: 15px;
        font-weight: 600;
        font-size: 21px;

        @media (max-width: $max-xl) {
          margin-right: 0;
        }

        @media (max-width: $max-sm) {
          font-size: 16px;
        }

        @media (max-width: $max-xs) {
          font-size: 14px;
        }
      }

      & > .callback {
        position: relative;
        margin-left: 15px;

        @media (max-width: $max-xl) {
          margin-left: 0;
          margin-top: 14px;
        }

        input {
          width: 100%;
          max-width: 464px;
          height: 48px;
          padding: 0 184px 0 45px;
          color: #202020;
          font-size: 21px;
          font-weight: 500;
          font-family: 'Montserrat', sans-serif;
          border-radius: 50px;

          @media (max-width: $max-md) {
            max-width: 362px;
            padding: 0 140px 0 15px;
          }
        }

        button {
          position: absolute;
          top: 0;
          right: 0;
          min-width: 184px;
          height: 48px;
          color: $white;
          background-color: $black;
          box-shadow: none;
          border: none;
          border-radius: 50px;
          font-weight: 600;
          font-size: 18px;
          transition: all .3s;
          cursor: pointer;
          z-index: 2;

          @media (max-width: $max-md) {
            min-width: 140px;
            font-size: 17px;
          }

          @media (max-width: $max-sm) {
            font-size: 15px;
          }

          &:hover {
            text-shadow: 0 0 2px $white;
          }
        }
      }
    }
  }
}
</style>
