export const portfolio = [
  {
    id: 59,
    imageUrl: "59/preview.jpg",
    title:
      "Відеопрезентація виробничих потужностей компанії Spunrise. Created by Octopus",
    shortDescription:
      'Демонстрація виробничих потужностей, технологій виробництва продукції, та масштаби підприємства "Spunrise"',
    description: null,
    price: "2000$",
    term: "10 днів",
    videoId: "EssIBigj4bw",
    priceValue: 2000,
    types: ["business"]
  },
  {
    id: 60,
    imageUrl: "60/preview.jpeg",
    title:
      'Snippet до ролику "Відеопрезентація виробничих потужностей компанії Spunrise". Created by Octopus',
    shortDescription: null,
    description: null,
    price: "2000$",
    term: "2 дні",
    videoId: "zXQ-_uugljw",
    priceValue: 2000,
    types: ["social"]
  },
  {
    id: 61,
    imageUrl: "61/preview.jpg",
    title:
      "Корпоративне відео для Piraeus Bank - Business continuity plan BCP. Created by Octopus",
    shortDescription:
      "Відео для внутрішнього використання в структурі банку Піреус, план дій на випадок надзвичайних ситуацій, що робити щоб робота банку не припинялась. Та одночасно контент для підтримання корпоративного духу!",
    description: null,
    price: "3000$",
    term: "14 днів",
    videoId: "yOcId4rWff4",
    priceValue: 3000,
    types: ["team_building"]
  },
  {
    id: 62,
    imageUrl: "62/preview.png",
    title:
      'Реклама нової серії вологих рушників "Чиста Перемога" від корпорації Biosphere. Created by Octopus',
    shortDescription:
      'Реклама продукту створеного для воїнів ЗСУ, демонстрація використання вологих рушників бренду "Чиста Перемога" в умовах реального застосування.',
    description: null,
    price: "3000$",
    term: "20 днів",
    videoId: "xwzw8Ke73Qs",
    priceValue: 3000,
    types: ["adv"]
  },
  {
    id: 62.1,
    imageUrl: "62.1/preview.png",
    title:
      'Коротке відео для реклами у Shorts/Reels/TikTok нової серіїї вологих рушників "Чиста Перемога" від корпорації Biosphere. Created by Octopus',
    shortDescription: null,
    description: null,
    price: "200$",
    term: "2 дні",
    videoId: null,
    videoUrl: "62.1/video-1",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 63,
    imageUrl: "63/preview.png",
    title:
      "Соціальний ролик про труднощі повернення військовослужбовців до мирного життя. Created by Octopus",
    shortDescription:
      "Ролик створений для мотивації бізнесу до працевлаштування наших героїв для їх швидкого повернення до мирного життя.",
    description: null,
    price: "1000$",
    term: "7 днів",
    videoId: "b1CfvYh5WWY",
    priceValue: 1000,
    types: ["adv"]
  },
  {
    id: 64,
    imageUrl: "64/preview.png",
    title:
      'Тизер до курсу з 16 лекцій на тему "Мова програмування Solidity та технології Smart Contracts" від компанії Distributed Lab. Created by Octopus',
    shortDescription: null,
    description: null,
    price: "200$",
    term: "90 днів",
    videoId: "YaL6SUcH79c",
    priceValue: 200,
    types: ["services"]
  },
  {
    id: 65,
    imageUrl: "65/preview.png",
    title:
      'Відео лекція Олександра Курбатова на тему "Ethereum - світовий комп\'ютер, платформа, криптовалюта" від компанії Distributed Lab. Created by Octopus',
    shortDescription: null,
    description: null,
    price: "500$",
    term: "3 дні",
    videoId: "YaL6SUcH79c",
    priceValue: 500,
    types: ["expert_opinion"]
  },
  {
    id: 66,
    imageUrl: "66/preview.png",
    title:
      'Відео лекція Артема Чистякова на тему "Транзакції та блоки Ethereum" від компанії Distributed Lab. Created by Octopus',
    shortDescription: null,
    description: null,
    price: "500$",
    term: "3 дні",
    videoId: "330mNuwfRdI",
    priceValue: 500,
    types: ["expert_opinion"]
  },
  {
    id: 67,
    imageUrl: "67/preview.jpg",
    title:
      "Відео з нагоди привітання зі 100 річчям гірничодобувної компанії AKW Ukrainian Kaolin Company. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "3000$",
    term: "21 день",
    videoId: "IuQKM_IgGI0",
    priceValue: 3000,
    types: ["team_building"]
  },
  {
    id: 68,
    imageUrl: "68/preview.png",
    title:
      "Реклама атомарного кондиціонеру металу для відновлення двигуна автомобілів XADO. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "3000$",
    term: "14 днів",
    videoId: "H7dEnhC71ZA",
    priceValue: 3000,
    types: ["review"]
  },
  {
    id: 69,
    imageUrl: "69/preview.jpg",
    title:
      "Промо відео для Linden Luxury Residences про власне виробництво гранітної плитки для оздоблення елітного житлового комплексу. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "2000$",
    term: "21 день",
    videoId: "MxBwAWwmG5Q",
    priceValue: 2000,
    types: ["review"]
  },
  {
    id: 70,
    imageUrl: "70/preview.jpg",
    title:
      "Рекламно-ознайомлювальне відео по встановленю вінірів у стоматології Burov Clinic. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "7 днів",
    videoId: "-ZfZ35kZAEI",
    priceValue: 1000,
    types: ["business"]
  },
  {
    id: 71,
    imageUrl: "71/preview.jpg",
    title:
      "Реклама першого в Україні трикомпонентного післясходового гербіциду системної дії для захисту посівів від ALFA SMART AGRO. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "3000$",
    term: "21 день",
    videoId: "Ie1w5Jy1sFg",
    priceValue: 3000,
    types: ["business"]
  },
  {
    id: 72,
    imageUrl: "72/preview.jpg",
    title:
      "Реклама захистних стекол SPX Shield для смартфонів на ринок Великої Британії. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "3000$",
    term: "21 день",
    videoId: "A_HpjxQgqgc",
    priceValue: 3000,
    types: ["adv"]
  },
  {
    id: 72.1,
    imageUrl: "72.1/preview.png",
    title:
      "Коротке відео для реклами у Shorts/Reels/TikTok захистних стекол SPX Shield для смартфонів на ринок Великої Британії. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "200$",
    term: "3 дні",
    videoId: null,
    videoUrl: "72.1/video-1",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 73,
    imageUrl: "73/preview.jpeg",
    title:
      "Рекламно-ознайомлювальне відео про онкологічне відділення у клініці Мануфактура. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "2000$",
    term: "14 днів",
    videoId: "ACqwTQ42YWs",
    priceValue: 2000,
    types: ["business"]
  },
  {
    id: 74,
    imageUrl: "74/preview.jpg",
    title:
      "Snippet live-зйомки форуму від компанії MEDAVITA UKRAINE. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "200$",
    term: "3 дні",
    videoId: "mFHA5hg_gJo",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 75,
    imageUrl: "75/preview.jpg",
    title:
      "Промо-ролик для компанії MEDAVITA UKRAINE по використанню спеціалізованої італійської косметики для безпечного фарбування волося (Модель Яна). Created by Octopus",
    shortDescription: null,
    description: null,
    price: "500$",
    term: "7 днів",
    videoId: "mCCsBBZNFZ8",
    priceValue: 500,
    types: ["business"]
  },
  {
    id: 76,
    imageUrl: "76/preview.jpg",
    title:
      "Промо-ролик для компанії MEDAVITA UKRAINE по використанню спеціалізованої італійської косметики для безпечного фарбування волося (Модель Вероніка). Created by Octopus",
    shortDescription: null,
    description: null,
    price: "500$",
    term: "7 днів",
    videoId: "cY35OPHsX_w",
    priceValue: 500,
    types: ["review"]
  },
  {
    id: 77,
    imageUrl: "77/preview.jpg",
    title:
      "Shorts/Reels/TikTok відео для компанії MEDAVITA UKRAINE по використанню спеціалізованої італійської косметики для безпечного фарбування волося (Модель Лєра). Created by Octopus",
    shortDescription: null,
    description: null,
    price: "200$",
    term: "3 дні",
    videoId: "7l6ZVl97EiA",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 77.1,
    imageUrl: "77.1/preview.jpg",
    title:
      "Shorts/Reels/TikTok відео для компанії MEDAVITA UKRAINE - компіляція робіт технолога Балацького. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "200$",
    term: "3 дні",
    videoId: null,
    videoUrl: "77.1/video-1",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 78,
    imageUrl: "78/preview.jpg",
    title:
      "Відео огляд електричних котлів серіїї Hercules для офіційного YouTube каналу бренду WILLER. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "10 днів",
    videoId: "GMGs5dorEYE",
    priceValue: 1000,
    types: ["youtube"]
  },
  {
    id: 79,
    imageUrl: "79/preview.jpg",
    title:
      "Відео огляд електричних котлів серіїї Flat Color для офіційного YouTube каналу бренду WILLER. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "10 днів",
    videoId: "m7eru2au4I4",
    priceValue: 1000,
    types: ["youtube"]
  },
  {
    id: 80,
    imageUrl: "80/preview.jpg",
    title:
      "Відео огляд електричних бойлерів Comfee серіїї CW/CD для офіційного YouTube каналу компанії TEPLORADIST. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "10 днів",
    videoId: "SkKJPcilubg",
    priceValue: 1000,
    types: ["review"]
  },
  {
    id: 81,
    imageUrl: "81/preview.jpg",
    title:
      "Відео огляд електричних бойлерів Comfee серіїї LU/LO для офіційного YouTube каналу компанії TEPLORADIST. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "10 днів",
    videoId: "Qm4H_qVXdks",
    priceValue: 1000,
    types: ["review"]
  },
  {
    id: 82,
    imageUrl: "82/preview.jpg",
    title:
      "Відео огляд електричних бойлерів Midea серіїї FN Global для офіційного YouTube каналу компанії TEPLORADIST. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "10 днів",
    videoId: "uiTxkF5DDCI",
    priceValue: 1000,
    types: ["youtube"]
  },
  {
    id: 83,
    imageUrl: "83/preview.jpg",
    title:
      "Відео огляд електричних бойлерів Midea серіїї ECO для офіційного YouTube каналу компанії TEPLORADIST. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "10 днів",
    videoId: "WbGdeWrlLuU",
    priceValue: 1000,
    types: ["review"]
  },
  {
    id: 84,
    imageUrl: "84/preview.png",
    title:
      "Внутрішній навчальний ролик для сервіс-інженерів компанії Bestpresso по обслуговуванню вендінгових апаратів. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "14 днів",
    videoId: "zYnwfMfMwJY",
    priceValue: 1000,
    types: ["team_building"]
  },
  {
    id: 85,
    imageUrl: "85/preview.jpg",
    title:
      "Соціально-інформаційний ролик для Українського Інституту Національної Пам'яті (УІНП) про повернення національної пам'яті країни. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "3000$",
    term: "50 днів",
    videoId: "rzSaSL1pIhw",
    priceValue: 3000,
    types: ["review"]
  },
  {
    id: 86,
    imageUrl: "86/preview.jpg",
    title:
      "Анімаційна реклама нових серветок Smile від корпорації Biosphere. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "14 днів",
    videoId: "0n49NKQOKNY",
    priceValue: 1000,
    types: ["services"]
  },
  {
    id: 87,
    imageUrl: "87/preview.png",
    title:
      "Анімаційний ролик-інструкція по користуванню новим застосунком WinBank від Piraeus Bank. Created by Octopus",
    shortDescription: null,
    description: null,
    price: "1000$",
    term: "14 днів",
    videoId: "LBVUb-ZKrUU",
    priceValue: 1000,
    types: ["team_building"]
  },
  {
    id: 22,
    imageUrl: ["22/preview.webp", "22/preview.jpg"],
    title:
      "TikTok для EcoBuildUkraine - Сучасний український будинок з соломи. Created by Octopus",
    shortDescription:
      "Сценарій створено командою Octopus, відеоряд змонтовано з контенту, що знятий, на смартфон замовника.",
    description: null,
    price: "200$",
    term: "1 день",
    videoId: "fWXWMWDK3HM",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 23,
    imageUrl: ["23/preview.webp", "23/preview.jpg"],
    title:
      "Promo Reels для Blade Runner - Сучасна академія перукарського мистецтва. Created by Octopus",
    shortDescription:
      "Монтаж основного промо компанії у формат 9:16 для Instagram Reels, TikTok, YouTube Shorts.",
    description: null,
    price: "200$",
    term: "1 день",
    videoId: "kNS-5jqrl80",
    priceValue: 200,
    types: ["adv"]
  },
  {
    id: 24,
    imageUrl: ["24/preview.webp", "24/preview.png"],
    title:
      "Promo Reels для Medavita - Комплекс для боротьби з алопецією. Created by Octopus",
    shortDescription:
      "Монтаж основного промо компанії у формат 9:16 для Instagram Reels, TikTok, YouTube Shorts.",
    description: null,
    price: "200$",
    term: "1 день",
    videoId: "RdBuGXBooZE",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 25,
    imageUrl: ["25/preview.webp", "25/preview.jpg"],
    title:
      "Рекламний креатив для Blade Runner - Курс Барбера. Created by Octopus",
    shortDescription:
      "Відеокреатив підготовлений для таргетингової реклами у формат 9:16 для Instagram Reels, YouTube Shorts.",
    description: null,
    price: "200$",
    term: "1 день",
    videoId: "nJKQR2qm02E",
    priceValue: 200,
    types: ["adv"]
  },
  {
    id: 26,
    imageUrl: ["26/preview.webp", "26/preview.jpg"],
    title:
      "Teaser Reels для Blade Runner - Курс підвищення кваліфікації для Барберів. Created by Octopus",
    shortDescription:
      "Реклама навчального курсу змонтована у формат 9:16 для Instagram Reels, TikTok, YouTube Shorts.",
    description: null,
    price: "200$",
    term: "1 день",
    videoId: "OVcNgyt1Dpg",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 27,
    imageUrl: ["27/preview.webp", "27/preview.png"],
    title: "Самопрезентація блогера. Created by Octopus",
    shortDescription:
      "Самопіар блогера з демонстрацією його успіху шляхом покупки автомобіля. Знятий відразу в вертикальному варіанті на професійне обладнання.",
    description: null,
    price: "500$",
    term: "5 днів",
    videoId: "C5SBqQ6s5BM",
    priceValue: 500,
    types: ["social"]
  },
  {
    id: 28,
    imageUrl: ["28/preview.webp", "28/preview.jpg"],
    title:
      "Персональна історія учасника курсу від Blade Runner. Created by Octopus",
    shortDescription:
      "Монтаж персоналізованого відео під учасника курсу від академії Blade Runner у формат 9:16 для Instagram Reels, TikTok, YouTube Shorts. Першоджерело звичайне горизонтальне відео.",
    description: null,
    price: "200$",
    term: "2 дні",
    videoId: "KrSZ6qWZXLo",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 29,
    imageUrl: ["29/preview.webp", "29/preview.jpg"],
    title: "Шлях учасника курсу від Blade Runner. Created by Octopus",
    shortDescription:
      "Монтаж персоналізованого відео під учасника курсу від академії Blade Runner у формат 9:16 для Instagram Reels, TikTok, YouTube Shorts. Першоджерело звичайне горизонтальне відео.",
    description: null,
    price: "200$",
    term: "2 дні",
    videoId: "XF2QmlFnqFc",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 30,
    imageUrl: ["30/preview.webp", "30/preview.jpg"],
    title: "Історія учасника курсу від Blade Runner. Created by Octopus",
    shortDescription:
      "Монтаж персоналізованого відео під учасника курсу від академії Blade Runner у формат 9:16 для Instagram Reels, TikTok, YouTube Shorts. Першоджерело звичайне горизонтальне відео.",
    description: null,
    price: "200$",
    term: "2 дні",
    videoId: "eBFD5cYhKJE",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 31,
    imageUrl: ["31/preview.webp", "31/preview.jpg"],
    title:
      "TikTok для EcoBuildUkraine - Трикутні будинки з Японії, в рідних Карпатах. Created by Octopus",
    shortDescription:
      "Створення сценарію та монтаж під нього контенту, що знятий, на різні пристрої в різний час, різними людьми. Контент надав замовник. Формат фінального відео 9:16 для Instagram Reels, TikTok, YouTube Shorts.",
    description: null,
    price: "200$",
    term: "1 день",
    videoId: "w1PEPK4l5Fo",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 32,
    imageUrl: ["32/preview.webp", "32/preview.png"],
    title:
      "TikTok BackStage роботи команди Octopus - Самопрезентація блогера. Created by Octopus",
    shortDescription:
      "Демонстрація роботи знімальної команди Octopus у формат 9:16 для Instagram Reels, TikTok, YouTube Shorts.",
    description: null,
    price: "200$",
    term: "1 день",
    videoId: "Fi_K1fkCork",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 33,
    imageUrl: ["33/preview.webp", "33/preview.png"],
    title:
      "Експертна думка адвоката АО ALTEXA - Як виграти судовий спір з банком. Created by Octopus",
    shortDescription:
      'Інформаційний контент від Адвоката з практикою для приваблення нових клієнтів через сайт АО "ALTEXA".',
    description: null,
    price: "200$",
    term: "3 дні",
    videoId: "0f-jvp-yT1k",
    priceValue: 200,
    types: ["expert_opinion"]
  },
  {
    id: 34,
    imageUrl: ["34/preview.webp", "34/preview.jpg"],
    title:
      "Teaser для Blade Runner - Курс підвищення кваліфікації для Барберів. Created by Octopus",
    shortDescription: "Контент для реклами навчального курсу від Blade Runner.",
    description: null,
    price: "200$",
    term: "2 дні",
    videoId: "IOEe6Eiksh8",
    priceValue: 200,
    types: ["review"]
  },
  {
    id: 35,
    imageUrl: ["35/preview.webp", "35/preview.jpg"],
    title:
      "Інформаційне відео для EcoBuildUkraine - Будинки з соломи - екологічно, тепло, практично, економно. Created by Octopus",
    shortDescription:
      "Презентація продукту, діяльності, інформаційне відео, рекламне відео все в 1 ролику для YouTube каналу будівельної компанії EcoBuildUkraine.",
    description: null,
    price: "1000$",
    term: "21 день",
    videoId: "g0NVd7yMKiM",
    priceValue: 1000,
    types: ["youtube"]
  },
  {
    id: 36,
    imageUrl: ["36/preview.webp", "36/preview.jpg"],
    title:
      "BackStage фотосесії BladeRunner - Етнокіберпанк. Created by Octopus",
    shortDescription:
      "Контент для набору нових підписників на YouTube канал Blade Runner.",
    description: null,
    price: "500$",
    term: "7 днів",
    videoId: "xhFEA22CJNs",
    priceValue: 500,
    types: ["youtube"]
  },
  {
    id: 37,
    imageUrl: ["37/preview.webp", "37/preview.jpg"],
    title:
      "Відеопрезентація сучасної академії перукарського мистецтва Blade Runner. Created by Octopus",
    shortDescription:
      "Промо створене командою Octopus - ідея, сценарій, знімальний процес, постпродакшн, професійна озвучка. Мета розкрити всі переваги академії та трансформацію її учнів.",
    description: null,
    price: "2000$",
    term: "30 днів",
    videoId: "7OryDn4ClIM",
    priceValue: 2000,
    types: ["business"]
  },
  {
    id: 38,
    imageUrl: ["38/preview.webp", "38/preview.png"],
    title:
      "Інформаційний ролик для Medavita - Доглядова косметика. Created by Octopus",
    shortDescription:
      "Демонстрація ефекту використання доглядової косметики бренду Medavita на моделі.",
    description: null,
    price: "500$",
    term: "5 днів",
    videoId: "5JSSDCQtMoo",
    priceValue: 500,
    types: ["review"]
  },
  {
    id: 39,
    imageUrl: ["39/preview.webp", "39/preview.png"],
    title:
      "Відеоінструкція по обслуговуванню інверторного бензинового генератора ТМ Dusson. Created by Octopus",
    shortDescription:
      "Коротка версія ролику про обслуговування генератора створена для постингу в соціальних мережах.",
    description: null,
    price: "200$",
    term: "3 дні",
    videoId: "4FpZYmj0PBc",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 40,
    imageUrl: ["40/preview.webp", "40/preview.png"],
    title:
      "Відеопрезентація для Medavita - Процедура догляду за волоссям. Created by Octopus",
    shortDescription:
      "Демонстрація ефекту використання косметики бренду Medavita.",
    description: null,
    price: "500$",
    term: "3 дні",
    videoId: "PkS8avN976k",
    priceValue: 500,
    types: ["review"]
  },
  {
    id: 41,
    imageUrl: ["41/preview.webp", "41/preview.png"],
    title:
      "Відеопрезентація будівельна компанія Onix Build. Created by Octopus",
    shortDescription:
      "Огляд зданих в експлуатацію житлових комплексів та об'єктів що зараз будуються по Україні компанією Onix Build.",
    description: null,
    price: "1000$",
    term: "10 днів",
    videoId: "PVJw9yN9128",
    priceValue: 1000,
    types: ["business"]
  },
  {
    id: 42,
    imageUrl: ["42/preview.webp", "42/preview.png"],
    title:
      "Реклама інверторного бензинового генератора ТМ Dusson. Created by Octopus",
    shortDescription:
      "Рекламний ролик для лідогенерації з метою продажу інверторного бензинового генератора ТМ Dusson.",
    description: null,
    price: "500$",
    term: "7 днів",
    videoId: "9S2GFtBvtpc",
    priceValue: 500,
    types: ["adv"]
  },
  {
    id: 43,
    imageUrl: ["43/preview.webp", "43/preview.png"],
    title:
      "Детальна інструкція по обслуговуванню інверторного бензинового генератора ТМ Dusson",
    shortDescription:
      "Інформаційний контент для покупців інверторного бензинового генератора ТМ Dusson, що знімає питання по експлуатації генератора.",
    description: null,
    price: "500$",
    term: "5 днів",
    videoId: "2udGdjO8Rj8",
    priceValue: 500,
    types: ["youtube"]
  },
  {
    id: 44,
    imageUrl: ["44/preview.webp", "44/preview.png"],
    title:
      "Відео для дитячого ютуб каналу Аня&Даша Li  - Пригоди ХагіВагі. Created by Octopus",
    shortDescription:
      "На 100% створений контент командою Octopus - ідея, сценарій, підбір акторів, створення реквізиту, знімальний процес, постпродакшн, професійна озвучка.",
    description: null,
    price: "1000$",
    term: "21 день",
    videoId: "EXvDgP5p8Dc",
    priceValue: 1000,
    types: ["adv"]
  },
  {
    id: 45,
    imageUrl: ["45/preview.webp", "45/preview.png"],
    title:
      "Відеовідгук про Blade Runner -  Іван, курсу підвищення кваліфікації Барбера. Created by Octopus",
    shortDescription:
      "Живий відгук учасника курсу, що ділиться враження від пройденого курсу від Blade Runner, на монтажі підкріплений кадрами з відзнятого матеріалу в ході курсу.",
    description: null,
    price: "500$",
    term: "7 днів",
    videoId: "Ft0WYeYXXg4",
    priceValue: 500,
    types: ["youtube"]
  },
  {
    id: 46,
    imageUrl: ["46/preview.webp", "46/preview.png"],
    title:
      "Відеозвіт - підсумки навчального курсу підвищення кваліфікації Барбера Blade Runner. Created by Octopus",
    shortDescription:
      "Контент створений з метою реклами та продажу наступних навчальних курсів академії Blade Runner.",
    description: null,
    price: "500$",
    term: "7 днів",
    videoId: "VM-6fSwZKLA",
    priceValue: 500,
    types: ["review"]
  },
  {
    id: 47,
    imageUrl: ["47/preview.webp", "47/preview.png"],
    title:
      "Експертна думка адвоката АО ALTEXA - Чи можуть виселити під час війни. Created by Octopus",
    shortDescription:
      'Інформаційний контент від Адвоката з практикою для приваблення нових клієнтів через сайт АО "ALTEXA".',
    description: null,
    price: "200$",
    term: "3 дні",
    videoId: "WH9tZ5IkOUE",
    priceValue: 200,
    types: ["expert_opinion"]
  },
  {
    id: 48,
    imageUrl: ["48/preview.webp", "48/preview.png"],
    title:
      "Фільмування практичного заняття в ході курсу підвищення кваліфікації Барбера від Blade Runner - Зачіска Сайдинг. Created by Octopus",
    shortDescription:
      "Повне практичне заняття, зведене з двох камер для створення більш цікавого кадру. Приклад правильної зйомки навчального контенту.",
    description: null,
    price: "500$",
    term: "5 днів",
    videoId: "QPtoDi1nQu0",
    priceValue: 500,
    types: ["youtube"]
  },
  {
    id: 49,
    imageUrl: ["49/preview.webp", "49/preview.png"],
    title:
      "Інтерв'ю учасників курсу підвищення кваліфікації Барбера від BladeRunner. Created by Octopus",
    shortDescription:
      "Враження учасників, записані без сценарію, що пройшли курс по підвищенню кваліфікації від академії Blade Runner.",
    description: null,
    price: "200$",
    term: "5 днів",
    videoId: "qH7NFLupiuk",
    priceValue: 200,
    types: ["youtube"]
  },
  {
    id: 50,
    imageUrl: ["50/preview.webp", "50/preview.jpg"],
    title:
      "Лекція на тему - Кудрі. В ході курсу підвищення кваліфікації Барбера від Blade Runner. Created by Octopus",
    shortDescription:
      "Лекція перед початком практичного заняття на тему Кудрі, від одного з лекторів курсу по підвищенню кваліфікації від академії Blade Runner.",
    description: null,
    price: "500$",
    term: "5 днів",
    videoId: "M-xB_HMT9A0",
    priceValue: 500,
    types: ["expert_opinion"]
  },
  {
    id: 51,
    imageUrl: ["51/preview.webp", "51/preview.png"],
    title:
      "Відеозвіт - підсумки презентації продукції бренду Medavita. Created by Octopus",
    shortDescription:
      "Відеозвіт про подію для підписників соціальних сторінок компанії Medavita.",
    description: null,
    price: "200$",
    term: "2 дні",
    videoId: "lJHPKEiEELg",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 52,
    imageUrl: ["52/preview.webp", "52/preview.png"],
    title:
      "Промо ролик що ненав'язливо пропагує участь в навчальних курсах академії Blade Runner. Created by Octopus",
    shortDescription:
      "Відеозвіт після 2-х місяців курсу, що демонструє всі переваги такого формату навчання та ненав'язливо закликає придбати наступний курс від академії Blade Runner.",
    description: null,
    price: "200$",
    term: "4 дні",
    videoId: "jKHFLXtc-jQ",
    priceValue: 200,
    types: ["adv"]
  },
  {
    id: 53,
    imageUrl: ["53/preview.webp", "53/preview.jpg"],
    title:
      "Історія становлення одного із запрошених лекторів академії Blade Runner. Created by Octopus",
    shortDescription:
      "Надихаюча історія успіху одного з провідних майстрів в Barber індустрії, записана в ході фільмування подій курсу підвищення кваліфікації від академії Blade Runner.",
    description: null,
    price: "200$",
    term: "2 дні",
    videoId: "6m8tSc_XKTY",
    priceValue: 200,
    types: ["youtube"]
  },
  {
    id: 54,
    imageUrl: ["54/preview.webp", "54/preview.jpg"],
    title:
      "Розкриття ідеї образу від учасниці та моделі курсу для колористів від академії Blade Runner. Created by Octopus",
    shortDescription:
      "Контент що показує можливості для учасників навчальних курсів від blade Runner в тому щоб проявити власну креативність.",
    description: null,
    price: "200$",
    term: "2 дні",
    videoId: "dFEKc6XmUlQ",
    priceValue: 200,
    types: ["youtube"]
  },
  {
    id: 55,
    imageUrl: ["55/preview.webp", "55/preview.png"],
    title:
      "Детальний розбір зачіски - Шторки. В ході курсу підвищення кваліфікації Барбера від Blade Runner. Created by Octopus",
    shortDescription:
      "Лекція перед початком практичного заняття по створенню зачіски Шторки, від одного з лекторів курсу по підвищенню кваліфікації від академії Blade Runner.",
    description: null,
    price: "500$",
    term: "5 днів",
    videoId: "ny-h3bCP9jg",
    priceValue: 500,
    types: ["expert_opinion"]
  },
  {
    id: 56,
    imageUrl: ["56/preview.webp", "56/preview.png"],
    title:
      "BackStage фотосесії BladeRunner - Випуск колористів. Created by Octopus",
    shortDescription:
      "Екзамен колористів що навчались в академії Blade Runner, проходив у форматі фотосесії.",
    description: null,
    price: "500$",
    term: "5 днів",
    videoId: "nONK1RhLqBc",
    priceValue: 500,
    types: ["youtube"]
  },
  {
    id: 57,
    imageUrl: ["57/preview.webp", "57/preview.jpg"],
    title:
      "Reels Історія учасниці курсу від Blade Runner для її персональної instagram сторінки. Created by Octopus",
    shortDescription:
      "Історія учасниці курсу від Blade Runner для її персональної instagram сторінки, у формат 9:16 для Instagram Reels, TikTok, YouTube Shorts.",
    description: null,
    price: "200$",
    term: "1 день",
    videoId: "tCuLeRDQSho",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 58,
    imageUrl: ["58/preview.webp", "58/preview.png"],
    title:
      "Контент для дитячого ютуб каналу Аня&Даша Li  - Їжа з різних країн світу. Created by Octopus",
    shortDescription:
      "На 100% створений контент командою Octopus - ідея, сценарій, створення реквізиту, знімальний процес, постпродакшн, професійна озвучка. Вік акторів від 2,5 до 14 років!).",
    description: null,
    price: "2000$",
    term: "30 днів",
    videoId: "76h8cfyiY1k",
    priceValue: 2000,
    types: ["youtube"]
  },
  {
    id: 1,
    imageUrl: "сarraro/preview.jpg",
    title:
      "Created by Octopus Презентація спеціалізованого садового трактора Carraro",
    shortDescription:
      "Розкриття теми, створення формату та стилю під запуск YouTube каналу для фрутінженірингової компанії “Smart Agro”",
    description:
      "Проєкт - YouTube канал для компанії Smart Agro 'Модерно - інформаційний контент, направлений на власників горіхових, фруктових садів та виноградників. За 25 хвилин були продемонстровані переваги використання спеціалізованого трактору та донесена фундаментальна інформація про плюси механізації праці.",
    price: "2000$",
    term: "21 день",
    videoId: "nCWEhMvFL04",
    priceValue: 2000,
    types: ["youtube"]
  },
  {
    id: 20,
    imageUrl: "willer/preview.jpg",
    title: "Created by Octopus Реклама бойлерів WILLER",
    shortDescription: "Реклама бойлерів WILLER",
    description: "",
    price: "3000$",
    term: "5 днів",
    videoId: "ciqlnoMXJpU",
    priceValue: 3000,
    types: ["adv"]
  },
  {
    id: 2,
    imageUrl: "crystal-forest/preview.jpg",
    title: "Created by Octopus Відеоогляд котеджного містечка Crystal Forest",
    shortDescription:
      "Огляд котеджного містечка для агенції нерухомості “At Home”",
    description:
      "Проєкт - YouTube канал для агенції At Home. Інформативний відеоогляд котеджного містечка під Києвом. За 5 хвилин було продемонстровано різноманітність містечка Crystal Forest та вкладена максимальна кількість інформації,яка допоможе швидше визначитись з правильним вибором будинку",
    price: "500$",
    term: "9 днів",
    videoId: "P6SwetATB1E",
    priceValue: 500,
    types: ["youtube"]
  },
  {
    id: 3,
    imageUrl: "privat-house/preview.jpg",
    title: "Created by Octopus Відеоогляд приватного будинку для продажу",
    shortDescription:
      "Огляд котеджного містечка для агенції нерухомості “At Home”",
    description:
      "Проєкт - YouTube канал для агенції At Home. Інформативний відеоогляд приватного будинку який виставлений на продаж.Ціль відеоролика познайомити майбутнього власника з будинком завітавши до кожної кімнати, та донести максимальну кількість інформації стосовно нього",
    price: "500$",
    term: "5 днів",
    videoId: "iE7q71agP6o",
    priceValue: 500,
    types: ["youtube"]
  },
  {
    id: 4,
    imageUrl: "eko-krovlya/preview.jpg",
    title:
      "Created by Octopus Що потрібно знати перш ніж замовити очеретяну покрівлю",
    shortDescription:
      'Огляд демонтування старого даху на нову очеретяну покрівлю для компанії "Eko Krovlya"',
    description:
      "Проєкт-YouTube канал для компанії Eko Krovlya. На прикладі даного об'єкту показали всі переваги, властивості та базову інформацію про очеретяну покрівлю. Все, що потрібно знати перед вибором даху розповіли та продемонстували в відеоролику",
    price: "1000$",
    term: "60 днів",
    videoId: "hbqZQqtQoKw",
    priceValue: 1000,
    types: ["youtube"]
  },
  // {
  //   id: 5,
  //   imageUrl: 'business-life-cmm/preview.jpg',
  //   title: 'Created by Octopus Експертна думка СММ фахівця. Як створити якісний контент',
  //   shortDescription: 'Експертна думка від СММ фахівця компанії Business Life, 5 порад для створення якісного контенту',
  //   description: "Відеоролик створений для HR - департаменту компанії Business Life. Мета ролику - підвищити зацікавленість нових співробітників до фірми",
  //   price: '200$',
  //   term: '5 днів',
  //   videoId: 'ySrmOvICGCM',
  //   priceValue: 200,
  //   types: ['expert_opinion'],
  // },
  {
    id: 6,
    imageUrl: "business-life-pm/preview.jpg",
    title:
      "Created by Octopus Експертна думка проджект-менеджера. Чому я обрала саме Business Life",
    shortDescription:
      "Особисті поради від Project-менеджера компанії Business Life, та як обрати саме Ту роботу",
    description:
      "Відеоролик створений для HR - департаменту компанії Business Life. Мета ролика- підвищити зацікавленість нових співробітників до фірми",
    price: "200$",
    term: "5 днів",
    videoId: "M4AV8D1NQpQ",
    priceValue: 200,
    types: ["expert_opinion"]
  },
  {
    id: 7,
    imageUrl: "dbu-208/preview.jpg",
    title: 'Created by Octopus Відео - візитка компанії "ДБУ-208"',
    shortDescription:
      'Відеопрезентація компанії "ДБУ-208", демонстрація професіоналізму команди та високоякісний підхід до своєї справи',
    description:
      "Інформативний відеоролик створений для компанії ДБУ - 208, з 2008 року компанія займається будівництвом доріг, та займає лідируючу позицію по Києву та Києвській області",
    price: "1000$",
    term: "14 днів",
    videoId: "NiCmhP2fAGM",
    priceValue: 1000,
    types: ["business"]
  },
  {
    id: 8,
    imageUrl: "wood-saloon/preview.jpg",
    title:
      "Created by Octopus Відеопрезентація столярного виробництва Салон дерева",
    shortDescription:
      "Презентація столярного виробництва, де надається повний комплекс з переробки деревини",
    description:
      "Столярне виробництво надає всі види послуг з переробки деревини, в відео продемонстровані етапи обробки, та багатофункціональність виробництва",
    price: "1000$",
    term: "21 день",
    videoId: "sMhO9MrLUn8",
    priceValue: 1000,
    types: ["business"]
  },
  {
    id: 9,
    imageUrl: "chibis/preview.jpg",
    title: "Created by Octopus Відеопрезентація стоматології Chibis",
    shortDescription:
      "Промо ролик для стоматології, зображення досвідчених фахівців, професійної техніки та дружнього колективу",
    description: "",
    price: "500$",
    term: "9 днів",
    videoId: "ylqHqcjKMQ8",
    priceValue: 500,
    types: ["business"]
  },
  {
    id: 10,
    imageUrl: "сarraro-backstage/preview.jpg",
    title:
      "Created by Octopus Backstage знімального процесу з відеопрезентації трактора Cararro",
    shortDescription:
      'Backstage знімального процесу з відеопрезентації трактора "Cararro"',
    description: "",
    price: "200$",
    term: "2 дні",
    videoId: "w_xsd2mraD8",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 11,
    imageUrl: "wright/preview.jpg",
    title: "Created by Octopus Презентація газонокосарок Wright",
    shortDescription:
      'Демонстрація косарок американського бренду Wright від дилера в Україні компанії "Smart Rain"',
    description: "",
    price: "200$",
    term: "2 дні",
    videoId: "0IxlZ36yd5k",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 12,
    imageUrl: "eko-krovlya-before-after/preview.jpg",
    title: "Created by Octopus заміна старого даху на нову очеретяну покрівлю",
    shortDescription:
      'Відеоролик в форматі До - Після, заміна старого даху на нову очеретяну покрівлю від компанії "Eko Krovlya"',
    description: "",
    price: "200$",
    term: "3 дні",
    videoId: "_UhG331ljRU",
    priceValue: 200,
    types: ["social"]
  },
  {
    id: 13,
    imageUrl: "novoselskiy/preview.jpg",
    title:
      "Created by Octopus Відеоогляд сучасного ремонту від компанії Novoselskiy design studio",
    shortDescription:
      'В 33 секундах продемонстрували 3 різні квартири від студії "Novoselskiy design"',
    description: "",
    price: "200$",
    term: "1 день",
    videoId: "PyfYFrc2h-M",
    priceValue: 200,
    types: ["social"]
  },
  // {
  //   id: 14,
  //   imageUrl: 'business-life-party/preview.jpg',
  //   title: 'Created by Octopus Відеозйомка корпоративу компанії Business Life',
  //   shortDescription: 'Зйомка новорічного корпоративу для формування корпоративної культури співробітникам компанії "Business Life"',
  //   description: '',
  //   price: '200$',
  //   term: '1 день',
  //   videoId: 'IaXqlZB45cE',
  //   priceValue: 200,
  //   types: ['team_building'],
  // },
  {
    id: 15,
    imageUrl: "opticair/preview.jpg",
    title: 'Created by Octopus Реклама препарату "Opticair"',
    shortDescription:
      "Бюджетна реклама біологічно активних добавок для Індійського виробника",
    description: "",
    price: "500$",
    term: "14 днів",
    videoId: "mMmShsZj1G0",
    priceValue: 500,
    types: ["adv"]
  },
  {
    id: 16,
    imageUrl: "carromoto/preview.jpg",
    title:
      'Created by Octopus Презентація автомобіля Audi Q7 компанії "Carromoto"',
    shortDescription:
      'Короткий огляд автомобіля придбаного на аукціоні в США компанією "Carromoto"',
    description: "",
    price: "200$",
    term: "5 днів",
    videoId: "i3MOmvGXcjI",
    priceValue: 200,
    types: ["review"]
  },
  {
    id: 17,
    imageUrl: "eko-krovlya-7-years/preview.jpg",
    title:
      "Created by Octopus Відеоогляд очеретяної покрівлі після 7-ми років експлуатації",
    shortDescription:
      'Відеоогляд очеретяної покрівлі від компанії "Eko Krovlya" після 7-ми років експлуатації',
    description: "",
    price: "200$",
    term: "5 днів",
    videoId: "l6Ui8KAXb0w",
    priceValue: 200,
    types: ["review"]
  },
  {
    id: 18,
    imageUrl: "wright-smart-rain/preview.jpg",
    title: "Created by Octopus Презентація товару газонокосарок Wright",
    shortDescription:
      'Інформативна презентація лінійки газонокосарок Wright від офіційного дилера в Україні компанії "Smart Rain"',
    description: "",
    price: "500$",
    term: "14 днів",
    videoId: "cNSWEZE5H5s",
    priceValue: 500,
    types: ["review"]
  },
  {
    id: 19,
    imageUrl: "lisnuku/preview.jpg",
    title: "Created by Octopus Відеоогляд будинку в с. Лісники",
    shortDescription:
      "Короткий огляд будинку від компанії “At Home” для реклами в соціальних мережах",
    description: "",
    price: "200$",
    term: "14 днів",
    videoId: "0kBZS5kx8II",
    priceValue: 200,
    types: ["review"]
  },
  {
    id: 21,
    imageUrl: "video-party/preview.jpg",
    title: "Created by Octopus Корпоративне відео для компанії Business Life",
    shortDescription: "Корпоративне відео для компанії Business Life",
    description:
      "Зйомка корпоративів, Team Building та будь-яких офісних вечірок",
    price: "200$",
    term: "5 днів",
    videoId: null,
    videoUrl: "video-party/video-1",
    priceValue: 200,
    types: ["team_building"]
  }
];
