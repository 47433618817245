<template>
  <canvas :id="targetId" :width="width" :height="height"></canvas>
</template>

<script>
import { lavaAnimation } from '@/plugins/lava-animation'

export default {
  name: 'LavaAnimation',
  props: {
    targetId: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: '#f71735',
    },
    quantity: {
      type: Number,
      default: 5,
    },
  },
  mounted() {
    this.$nextTick(() => {
      lavaAnimation(this.targetId, this.color, this.quantity).run()
    })
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

canvas {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  filter: blur(50px);
  opacity: 0.7;

  @media (max-width: $max-lg) {
    filter: blur(40px);
  }
}
</style>
