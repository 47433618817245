<template>
  <div id="portfolioCallbackDialog" style="display: none">
    <template v-if="item.title">
      <div id="popup-content">
        <div class="main-popup">
          <div class="main-popup__descr">
            {{ item.title }}
          </div>
        </div>
        <form class="callback form-callback main-popup-form" @submit.prevent="submit">
          <div class="main-popup-form-helper">
            <div class="callback__input-wrap main-popup-form__input-wrap">
              <div class="callback__input-wrap-helper main-popup-form__input-wrap-helper">
                <masked-input
                    v-model="formData.phone"
                    id="portfolio-phone"
                    mask="\+38 011 111 11 11"
                    placeholder="+38 093 192 70 57"
                    type="tel"
                    name="phone"
                    required
                ></masked-input>
                <label for="portfolio-phone">введіть ваш номер</label>
              </div>
            </div>
            <button class="callback__btn main-popup-form__btn" type="submit">
              Хочу
            </button>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import SubmitForm from '@/mixins/SubmitForm'

export default {
  name: 'PortfolioCallbackDialog',
  mixins: [SubmitForm],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        subject: '',
        phone: '',
      },
    }
  },
  watch: {
    item(newValue) {
      this.formData.subject = this.item.title

      if (!newValue.title) {
        this.formData.phone = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/assets/styles/vars';

#portfolioCallbackDialog {
  max-width: 840px;
  padding: 50px 100px 110px 40px;
  border-radius: 25px;
  background-color: #666666;

  @media (max-width: $max-md) {
    padding: 50px 40px 80px 40px;
  }
}
</style>
