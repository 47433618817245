<script>
import Meeting from "@/components/Meeting.vue";
import Contacts from "@/components/Contacts.vue";
import Algorithm from "@/components/Algorithm.vue";
import Portfolio from "@/components/Portfolio.vue";
import OurViews from "@/components/OurViews.vue";
import Intro from "@/components/Intro.vue";
import Promotion from "@/components/Promotion.vue";
import HeaderComponent from "@/components/Header.vue";
import FooterComponent from "@/components/Footer.vue";
import Services from "@/components/Services.vue";
import LocomotiveScroll from "locomotive-scroll";

export default {
  name: "HomePage",
  components: {
    Intro,
    Promotion,
    // Concept,
    OurViews,
    HeaderComponent,
    Algorithm,
    Services,
    Portfolio,
    Meeting,
    Contacts,
    FooterComponent
  },
  // metaInfo: {
  //   htmlAttrs: { lang: 'uk' },
  //   title: 'Octopus - створення та ведення YouTube каналів під ключ',
  //   meta: [
  //     {
  //       name: 'description',
  //       content: '✅ ▶️ YouTube канал під ключ  ◀ ️✅ Створення відеоконтенту для YouTube каналів. ▶️ Відеопродакшн повного циклу.  ▶️ Від створення відео до реклами.',
  //     },
  //   ]
  // },
  data() {
    return {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      scroll: null
    };
  },
  created() {
    window.addEventListener("resize", this.resizeEventHandler);
  },
  mounted() {
    if (this.innerWidth > 1000) {
      this.scroll = new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true
      });

      this.$nextTick(() => {
        this.updateScroll();
      });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  methods: {
    resizeEventHandler() {
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
    },
    updateScroll() {
      if (this.scroll) {
        window.setTimeout(() => {
          this.scroll.update();
        }, 500);
      }
    }
  }
};
</script>

<template>
  <div>
    <HeaderComponent :inner-width="innerWidth" />

    <div data-scroll-container>
      <Intro :inner-width="innerWidth" />
      <Promotion :inner-width="innerWidth" :inner-height="innerHeight" />
      <!--    <Concept/>-->
      <div class="center-wrap">
        <OurViews :inner-width="innerWidth" />
        <Algorithm :inner-width="innerWidth" :inner-height="innerHeight" />
      </div>
      <Services :inner-width="innerWidth" :inner-height="innerHeight" />
      <Portfolio :inner-width="innerWidth" @update-scroll="updateScroll" />
      <div class="bottom-wrap">
        <Meeting />
        <Contacts :inner-width="innerWidth" />
        <FooterComponent />
      </div>
    </div>

    <div id="popupThankyouError" class="popup-thankyou" style="display:none;">
      <div class="popup-thankyou__content">
        <h4 class="popup-thankyou__ttl">Виникла помилка!</h4>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
