export const service = [
  {
    id: 1,
    title: 'Ведення YouTube',
    imageUrl: 'vedennya-youtube.jpg',
    video: 'vedennya-youtube',
  },
  {
    id: 2,
    title: 'Презентація бізнесу',
    imageUrl: 'prezentaciya-biznesu.jpg',
    video: 'prezentaciya-biznesu',
  },
  {
    id: 3,
    title: 'Огляд/презентація товару',
    imageUrl: 'oglyad-prezentaciya-tovaru.jpg',
    video: 'oglyad-prezentaciya-tovaru',
  },
  {
    id: 4,
    title: 'Контент для соц. мереж',
    imageUrl: 'kontent-dlya-soc-merezh.jpg',
    video: 'kontent-dlya-soc-merezh',
  },
  {
    id: 5,
    title: 'Продаж послуг',
    imageUrl: 'prodazh-poslug.jpg',
    video: 'prodazh-poslug',
  },
  {
    id: 6,
    title: 'Креатив та реклама',
    imageUrl: 'kreativ-ta-reklama.jpg',
    video: 'kreativ-ta-reklama',
  },
  {
    id: 7,
    title: 'Корп. відео/Team building',
    imageUrl: 'korp-video-team-building.jpg',
    video: 'korp-video-team-building',
  },
  {
    id: 8,
    title: 'Експертна думка',
    imageUrl: 'ekspertna-dumka.jpg',
    video: 'ekspertna-dumka',
  },
]
