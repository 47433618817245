export const type = [
  { id: 1, name: "Презентація бізнесу", type: "business" },
  { id: 2, name: "Експертна думка", type: "expert_opinion" },
  { id: 3, name: "Огляд / Презентація товару", type: "review" },
  { id: 4, name: "Контент для соц. мереж", type: "social" },
  { id: 5, name: "Корп. відео / Team building", type: "team_building" },
  { id: 6, name: "Ведення YouTube", type: "youtube" },
  { id: 7, name: "Креатив та реклама", type: "adv" },
  { id: 7, name: "Продаж послуг", type: "services" }
];
