<template>
  <div>
    <section class="intro">
      <div class="intro-helper">
        <template v-if="innerWidth > 767">
          <div class="intro-video">
            <video
              muted
              autoplay
              loop
              :poster="require(`@/assets/image/intro-bg.jpg`)"
            >
              <source :src="`${baseUrl}video/main.webm`" type="video/webm" />
              <source :src="`${baseUrl}video/main.mp4`" type="video/mp4" />
            </video>
          </div>
        </template>

        <div class="container">
          <div class="intro__content">
            <h1 class="intro__ttl animate__animated animate__fadeInLeftBig">
              <strong>Спеціалізуємось на відеоконтенті для бізнесу</strong>
            </h1>
            <div
              class="intro__subttl animate__animated animate__fadeInRightBig"
            >
              Створюємо контент, що підкорює ваших клієнтів від ідеї до повної
              реалізації за адекватний бюджет
            </div>
            <div class="zsu animate__animated animate__fadeInLeftBig">
              <div class="zsu__item">10% на ЗСУ</div>
              <div class="zsu__item">
                10% з прибутку донатимо на ЗСУ. <br />
                Разом до перемоги!
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Intro",
  props: {
    innerWidth: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL
    };
  },
  computed: {
    imageBg() {
      return require(`@/assets/image/${
        this.innerWidth > 575 ? "intro-bg.jpg" : "intro-mob-bg.jpg"
      }`);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/intro";
</style>
