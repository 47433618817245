<template>
  <section id="promotion" class="promotion">
    <div
      class="promotion__content"
      data-scroll
      data-scroll-sticky
      data-scroll-target="#promotion"
    >
      <LavaAnimation
        target-id="promotion-animation"
        :width="innerWidth"
        :height="innerHeight"
      />

      <div>
        <h3>Унікальний бонус від Octopus</h3>
        Створимо додатковий контент для ваших соціальних мереж Instagram Reels,
        TikTok, YouTube Shorts в подарунок для наших клієнтів
        <div :class="['form-wrap', { 'form-wrap--open': isFormShown }]">
          <form class="form ajax-form" @submit.prevent="submitHandler">
            <div>
              <input
                id="meeting-name"
                name="name"
                type="text"
                autocomplete="name"
                placeholder="Ваше Ім’я"
                required
              />
              <masked-input
                v-model="formData.phone"
                id="meeting-phone"
                mask="\+38 011 111 11 11"
                placeholder="+38 093 192 70 57"
                type="tel"
                name="phone"
                required
              ></masked-input>
            </div>
            <button class="btn btn_green btn_h_60" @click="click">
              Отримати подарунок
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="images-container">
      <div class="images-wrapper">
        <div
          data-scroll
          data-scroll-speed="6"
          class="animate__animated animate__fadeInLeftBig"
        >
          <img
            v-webp:src="require('@/assets/image/promotion-1.webp')"
            :src="require('@/assets/image/promotion-1.jpg')"
            alt=""
          />
        </div>
        <div
          data-scroll
          data-scroll-speed="6"
          class="animate__animated animate__fadeInRightBig"
        >
          <img
            v-webp:src="require('@/assets/image/promotion-2.webp')"
            :src="require('@/assets/image/promotion-2.jpg')"
            alt=""
          />
        </div>
        <div
          data-scroll
          data-scroll-speed="4"
          class="animate__animated animate__fadeInLeftBig"
        >
          <img
            v-webp:src="require('@/assets/image/promotion-3.webp')"
            :src="require('@/assets/image/promotion-3.jpg')"
            alt=""
          />
        </div>
        <div
          data-scroll
          data-scroll-speed="4"
          class="animate__animated animate__fadeInRightBig"
        >
          <img
            v-webp:src="require('@/assets/image/promotion-4.webp')"
            :src="require('@/assets/image/promotion-4.jpg')"
            alt=""
          />
        </div>
        <!--        <div data-scroll data-scroll-speed="2" class="animate__animated animate__fadeInLeftBig">-->
        <!--          <img-->
        <!--              v-webp:src="require('@/assets/image/promotion-5.webp')"-->
        <!--              :src="require('@/assets/image/promotion-5.jpg')"-->
        <!--              alt=""-->
        <!--          >-->
        <!--        </div>-->
        <!--        <div data-scroll data-scroll-speed="2" class="animate__animated animate__fadeInRightBig">-->
        <!--          <img-->
        <!--              v-webp:src="require('@/assets/image/promotion-6.webp')"-->
        <!--              :src="require('@/assets/image/promotion-6.jpg')"-->
        <!--              alt=""-->
        <!--          >-->
        <!--        </div>-->
      </div>
    </div>

    <div id="popupPromotion" class="popup-thankyou" style="display:none;">
      <div class="popup-thankyou__content">
        <div>
          <h3>Унікальний бонус від Octopus</h3>
          Створимо додатковий контент для ваших соціальних мереж Instagram
          Reels, TikTok, YouTube Shorts в подарунок для наших клієнтів
          <div :class="['form-wrap', { 'form-wrap--open': isFormShown }]">
            <form class="form ajax-form" @submit.prevent="submitHandler">
              <div>
                <input
                  id="meeting-name"
                  name="name"
                  type="text"
                  autocomplete="name"
                  placeholder="Ваше Ім’я"
                  required
                />
              </div>
              <div>
                <masked-input
                  v-model="formData.phone"
                  id="meeting-phone"
                  mask="\+38 011 111 11 11"
                  placeholder="+38 093 192 70 57"
                  type="tel"
                  name="phone"
                  required
                ></masked-input>
              </div>
              <button class="btn btn_green btn_h_60" type="submit">
                Отримати подарунок
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SubmitForm from "@/mixins/SubmitForm";
import LavaAnimation from "@/components/LavaAnimation";

export default {
  name: "Promotion",
  mixins: [SubmitForm],
  components: { LavaAnimation },
  props: {
    innerWidth: {
      type: Number,
      required: true
    },
    innerHeight: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isFormShown: false,
      formData: {
        subject: "Хочу подарунок",
        name: "",
        phone: ""
      }
    };
  },
  mounted() {
    setTimeout(() => {
      const fancyInstance = window.$fancybox.show(
        [{ src: "#popupPromotion", type: "inline" }],
        {
          on: {
            destroy: () => {
              this.$store.commit("RESET_FANCY_INSTANCE");
            }
          }
        }
      );

      this.$store.commit("SET_FANCY_INSTANCE", fancyInstance);
    }, 30000);
  },
  methods: {
    click(e) {
      if (!this.isFormShown) {
        e.preventDefault();

        this.isFormShown = true;
      }
    },
    submitHandler() {
      this.submit()
        .then(() => {
          this.isFormShown = false;
        })
        .catch(() => {
          console.log("error");
        });
    }
  }
};
</script>

<style lang="scss">
@import "../assets/styles/vars";

.popup-thankyou {
  h3 {
    margin: 40px 0 10px;
    font-weight: 600;
    font-size: 34px;

    @media (max-width: $max-xxl) {
      margin-bottom: 15px;
      font-size: 28px;
    }

    @media (max-width: $max-lg) {
      font-size: 24px;
    }

    @media (max-width: $max-md) {
      font-size: 18px;
    }
  }

  .form-wrap {
    width: 100%;
    margin-top: 32px;

    form {
      position: relative;
      width: 386px;
      max-width: 940px;
      margin: 0 auto;

      @media (max-width: $max-lg) {
        width: 235px;
        max-width: 766px;
        padding-right: 235px;
      }

      @media (max-width: $max-md) {
        width: 100%;
        max-width: 420px;
        height: 40px;
        padding-right: 0;
        padding-bottom: 40px;
        border-radius: 16px;
      }

      div {
        display: flex;
        height: inherit;

        @media (max-width: $max-md) {
          flex-direction: column;
          width: 100%;
        }
      }

      input {
        width: 100%;
        height: 56px;
        padding: 0 15px;
        margin-bottom: 25px;
        font-size: 22px;
        border-radius: 30px;

        @media (max-width: $max-md) {
          height: 40px;
          font-size: 18px;
          text-align: center;
        }
      }

      button {
        width: 386px;
        height: 56px;
        border-radius: 30px;

        @media (max-width: $max-lg) {
          max-width: 235px;
        }

        @media (max-width: $max-md) {
          width: 100%;
          max-width: 100%;
          height: 40px;
          font-size: 17px;
          border-radius: 16px;
        }

        @media (max-width: $max-sm) {
          font-size: 15px;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@import "../assets/styles/vars";

.promotion {
  position: relative;
  padding: 60px 0;

  @media (min-width: 1025px) {
    overflow: hidden;
  }

  @media (max-width: $max-sm) {
    padding: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(18, 23, 34, 0.5);
      z-index: 2;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    @media (max-width: 1024px) {
      height: 100%;
    }

    & > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: calc(100% - 30px);
      max-width: 1098px;
      min-height: 325px;
      padding: 15px;
      font-weight: 500;
      font-size: 24px;
      line-height: 1.2;
      background: #121722;
      color: $white;
      z-index: 3;

      @media (max-width: $max-xxl) {
        min-height: 280px;
        font-size: 20px;
      }

      @media (max-width: $max-lg) {
        font-size: 16px;
      }

      @media (max-width: $max-md) {
        min-height: 275px;
        font-size: 13px;
      }

      h3 {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 34px;

        @media (max-width: $max-xxl) {
          margin-bottom: 15px;
          font-size: 28px;
        }

        @media (max-width: $max-lg) {
          font-size: 24px;
        }

        @media (max-width: $max-md) {
          font-size: 18px;
        }
      }

      .form-wrap {
        width: 100%;
        margin-top: 32px;

        form {
          position: relative;
          display: flex;
          width: 386px;
          max-width: 940px;
          height: 56px;
          margin: 0 auto;
          padding-right: 386px;
          border-radius: 30px;
          background-color: #fff;
          overflow: hidden;

          @media (max-width: $max-lg) {
            width: 235px;
            max-width: 766px;
            padding-right: 235px;
          }

          @media (max-width: $max-md) {
            width: 100%;
            max-width: 420px;
            height: 40px;
            padding-right: 0;
            padding-bottom: 40px;
            border-radius: 16px;
          }

          div {
            display: flex;
            height: inherit;

            @media (max-width: $max-md) {
              flex-direction: column;
              width: 100%;
            }
          }

          input {
            max-width: 282px;
            padding: 0 15px;
            height: inherit;
            font-size: 22px;

            @media (max-width: $max-lg) {
              max-width: 236px;
            }

            @media (max-width: $max-md) {
              width: 100%;
              max-width: 100%;
              height: 40px;
              font-size: 18px;
              text-align: center;
            }
          }

          button {
            position: absolute;
            right: 0;
            top: 0;
            width: 386px;
            height: inherit;
            border-radius: 30px;

            @media (max-width: $max-lg) {
              max-width: 235px;
            }

            @media (max-width: $max-md) {
              bottom: 0;
              top: auto;
              width: 100%;
              max-width: 100%;
              height: 40px;
              font-size: 17px;
              border-radius: 16px;
            }

            @media (max-width: $max-sm) {
              font-size: 15px;
            }
          }
        }

        &--open {
          form {
            width: auto;
            padding-left: 15px;

            @media (max-width: $max-lg) {
              padding-left: 10px;
            }

            @media (max-width: $max-md) {
              width: 100%;
              height: auto;
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .images {
    &-container {
      max-width: 1920px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: $min-sm) {
        min-height: 100vh;
      }

      img {
        display: block;
        height: auto;
        opacity: 0.9;
      }

      & > div {
        display: flex;
        width: 100%;

        &:nth-child(1) {
          justify-content: flex-end;

          img {
            width: 48%;
            max-width: 720px;
          }
        }

        &:nth-child(2) {
          margin-top: -50px;

          img {
            width: 48%;
            max-width: 720px;
          }
        }

        &:nth-child(3) {
          justify-content: flex-end;
          margin-top: 10px;

          img {
            width: 48%;
            max-width: 720px;
          }
        }

        &:nth-child(4) {
          @media (max-width: $max-sm) {
            display: none;
          }

          img {
            width: 48%;
            max-width: 650px;
          }
        }

        &:nth-child(5) {
          justify-content: flex-end;
          padding-right: 5%;

          @media (max-width: $max-sm) {
            display: none;
          }

          img {
            width: 40%;
            max-width: 620px;
          }
        }

        &:nth-child(6) {
          margin-top: -10px;
          padding-left: 3%;

          @media (max-width: $max-sm) {
            display: none;
          }

          img {
            width: 42%;
            max-width: 650px;
          }
        }

        @media (max-width: $max-sm) {
          margin: 0 !important;
          padding: 0 !important;

          img {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
