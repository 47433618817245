<template>
  <div id="videoDialog" style="display: none">
    <div class="order-popup order-popup_main">
      <div class="order-popup__wrap">
        <div>
          <template v-if="item.videoId">
            <youtube
                :video-id="item.videoId"
                ref="youtube"
                fitParent
                @ready="player.playVideo()"
            ></youtube>
          </template>
          <template v-else>
            <video
                v-if="item.imageUrl"
                controls
                width="100%"
                :poster="require(`@/assets/image/portfolio/${item.imageUrl}`)"
            >
              <source :src="`${baseUrl}video/portfolio/${item.videoUrl}.webm`" type="video/webm">
              <source :src="`${baseUrl}video/portfolio/${item.videoUrl}.mp4`" type="video/mp4">
            </video>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YoutubeDialog',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/vars';

.fancybox__backdrop {
  background: #000 !important;
}

.fancybox__content > .carousel__button.is-close {
  top: 55px !important;
  right: 63px !important;
}

.fancybox__content {
  width: 90%;
  max-width: 1600px;
  padding: 0;
  color: $white;
  background-color: transparent;
  border-radius: 65px;
  overflow: hidden;

  @media (max-width: $max-lg) {
    border-radius: 30px;
  }

  @media (max-width: $max-sm) {
    width: 100%;
  }

  @media (max-width: $max-xs) {
    border-radius: 20px;
  }

  .order-popup {
    position: relative;
    width: 100%;

    @media (max-width: $max-lg) {
      border-radius: 30px;
    }

    @media (max-width: $max-xs) {
      border-radius: 20px;
    }

    &_main {
      height: 100%;
      background: #000;
      overflow: hidden;
    }

    &__wrap {
      width: 100%;
      height: 100%;
      padding: 0 150px;

      @media (max-width: $max-xl) {
        padding: 0 50px;
      }

      @media (max-width: $max-md) {
        padding: 60px 0;
      }

      @media (max-width: $max-sm) {
        padding: 45px 0;
      }
    }
  }
}
</style>
