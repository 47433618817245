<script>
export default {
  computed: {
    fancyInstance() {
      return this.$store.state.fancyInstance;
    }
  },
  methods: {
    async submit() {
      const url = "mail.php";
      const data = this.formData;
      const regex = new RegExp(
        /^\+\d{2}\s{1}\d{3}\s{1}\d{3}\s{1}\d{2}\s{1}\d{2}/
      );

      if (!regex.test(data.phone)) return false;

      const arr = window.location.search.replace("?", "").split("&");

      if (arr.length) {
        arr.forEach(function(item) {
          const result = item.split("=");

          data[decodeURIComponent(result[0])] = decodeURIComponent(result[1]);
        });
      }

      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(data => {
          if (data.status !== 200) throw new Error("Sending error!");
          if (this.fancyInstance) this.fancyInstance.close();

          window.sessionStorage.setItem("thank-you-page-allowed", "1");

          this.$router.push("/thank-you");

          // window.$fancybox.show(
          //   [{ src: "#popupThankyouSuccess", type: "inline" }],
          //   {
          //     on: {
          //       destroy: () => {
          //         this.$store.commit("RESET_FANCY_INSTANCE");
          //       }
          //     }
          //   }
          // );

          if (this.formData.phone) {
            this.formData.phone = "";
          }

          if (this.formData.name) {
            this.formData.name = "";
          }

          if (this.isOpenedCallbackForm !== undefined) {
            this.isOpenedCallbackForm = false;
          }

          return Promise.resolve();
        })
        .catch(e => {
          console.log(e);

          if (this.fancyInstance) this.fancyInstance.close();

          window.$fancybox.show(
            [{ src: "#popupThankyouError", type: "inline" }],
            {
              on: {
                destroy: () => {
                  this.$store.commit("RESET_FANCY_INSTANCE");
                }
              }
            }
          );

          return Promise.reject();
        });
    }
  }
};
</script>
