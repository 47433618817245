<script>
export default {
  name: "ThankYouPage",
  beforeMount() {
    if (!(window.sessionStorage.getItem("thank-you-page-allowed") === "1")) {
      this.$router.push("/");
    }
  },
  mounted() {
    window.sessionStorage.removeItem("thank-you-page-allowed");
  },
  methods: {
    click() {
      this.$router.push("/");
    }
  }
};
</script>

<template>
  <div class="wrap">
    <div class="popup-thankyou">
      <div class="popup-thankyou__content">
        <h4 class="popup-thankyou__ttl">Дякуємо за заявку!</h4>
        <p>Наш спеціаліст найближчим часом зв'яжеться з Вами</p>
        <button class="btn btn_green btn_h_60" @click="click">
          На головну
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrap {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  text-wrap: nowrap;
  width: auto;
}
</style>
