<template>
  <section class="contacts">
    <div class="container">
      <div class="contacts__content">
        <div class="contacts__wrap">
          <div class="contacts__item">
            <span>Наш телефон:</span>
            <a href="tel:+380931927057" class="phone">
              +38 093 192 70 57
            </a>
          </div>
          <div class="contacts__item">
            <span>Соціальні мережі:</span>
            <div class="s">
              <div class="s__item s__item_t">
                <a
                    href="https://t.me/+380931927057"
                    rel="nofollow noopener"
                    class="s__link"
                    target="_blank"
                ></a>
              </div>
              <div class="s__item s__item_v">
                <a
                    href="viber://chat/?number=%2B380931927057"
                    rel="nofollow noopener"
                    class="s__link"
                    target="_blank"
                ></a>
              </div>
              <div class="s__item s__item_i">
                <a
                    href="https://www.instagram.com/createdby_octopus/"
                    rel="nofollow noopener"
                    class="s__link"
                    target="_blank"
                ></a>
              </div>
              <div class="s__item s__item_f">
                <a
                    href="https://www.facebook.com/Createdbyoctopus"
                    rel="nofollow noopener"
                    class="s__link"
                    target="_blank"
                ></a>
              </div>
              <div class="s__item s__item_y">
                <a
                    href="https://www.youtube.com/channel/UCu0MBXWVkt7IfymhrE3o29Q"
                    rel="nofollow noopener"
                    class="s__link"
                    target="_blank"
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div
            class="contacts__map"
            v-webp:bgs="imageBg"
        >
          <span>м. Київ,<br> вул. Нижній Вал 7</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contacts',
  props: {
    innerWidth: {
      type: Number,
      required: true,
    },
  },
  computed: {
    imageBg() {
      return [
          require(`@/assets/image/${this.innerWidth > 575 ? 'map.webp' : 'map-mob.webp'}`),
          require(`@/assets/image/${this.innerWidth > 575 ? 'map.jpg' : 'map-mob.jpg'}`),
      ]
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/vars';

.contacts {
  position: relative;
  padding-top: 20px;

  .container {
    @media (max-width: $max-lg) {
      padding: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $max-lg) {
      flex-direction: column;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 1020px);
    padding-left: 150px;
    text-align: left;

    @media (max-width: 1699px) {
      width: calc(100% - 850px);
      padding-left: 0;
    }

    @media (max-width: $max-xxl) {
      width: calc(100% - 700px);
    }

    @media (max-width: $max-xl) {
      width: calc(100% - 600px);
    }

    @media (max-width: $max-lg) {
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
    }

    @media (max-width: $max-md) {
      flex-direction: column;
    }
  }

  &__item {
    margin-bottom: 40px;

    @media (max-width: $max-lg) {
      text-align: center;
    }

    @media (max-width: $max-sm) {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    span {
      display: block;
      margin-bottom: 13px;
      font-size: 24px;
      font-weight: 500;

      @media (max-width: $max-md) {
        font-size: 20px;
      }

      @media (max-width: $max-sm) {
        font-size: 16px;
      }

      @media (max-width: $max-xs) {
        font-size: 12px;
      }
    }

    .phone {
      font-size: 28px;
      font-weight: 600;
      color: $white;
      text-shadow: 0 0 10px #009ab5;
      text-decoration: none;
      transition: color .3s ease;

      &:hover {
        @media (min-width: $min-lg) {
          color: $accent;
          text-decoration: none;
        }
      }

      @media (max-width: $max-md) {
        font-size: 26px;
      }

      @media (max-width: $max-sm) {
        font-size: 24px;
      }
      
      @media (max-width: $max-xs) {
        font-size: 21px;
      }
    }

    .s {
      display: flex;
      align-items: center;

      @media (max-width: $max-md) {
        justify-content: center;
      }

      &__item {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 1699px) {
          width: 50px;
          height: 50px;
          margin-right: 30px;
        }

        @media (max-width: $max-xxl) {
          width: 40px;
          height: 40px;
          margin-right: 25px;
        }

        @media (max-width: $max-xl) {
          margin-right: 20px;
        }

        @media (max-width: $max-md) {
          width: 50px;
          height: 50px;
        }

        @media (max-width: $max-sm) {
          width: 40px;
          height: 40px;
        }

        &:last-child {
          margin-right: 0;
        }

        &_t {
          background-image: url(../assets/image/tg.svg);
        }

        &_v {
          background-image: url(../assets/image/vb.svg);
        }

        &_i {
          background-image: url(../assets/image/in.svg);
        }

        &_f {
          background-image: url(../assets/image/fb.svg);
        }

        &_y {
          height: 55px;
          background-image: url(../assets/image/yt.svg);
        }
      }

      &__link {
        width: 100%;
        height: 100%;
        display: inline-block;
      }
    }
  }

  &__map {
    position: relative;
    width: 1020px;
    height: 290px;

    @media (max-width: 1699px) {
      width: 850px;
    }

    @media (max-width: $max-xxl) {
      width: 700px;
    }

    @media (max-width: $max-xl) {
      width: 600px;
    }

    @media (max-width: $max-lg) {
      width: 100%;
      height: 250px;
      margin-top: 40px;
    }

    @media (max-width: $max-sm) {
      height: 180px;
      margin-top: 30px;
    }

    @media (max-width: $max-xs) {
      height: 130px;
    }

    img {
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    span {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      font-size: 28px;
      font-weight: 500;
      text-align: center;

      br {
        display: none;
      }

      @media (max-width: $max-lg) {
        font-size: 24px;
      }

      @media (max-width: $max-md) {
        font-size: 20px;

        br {
          display: block;
        }
      }

      @media (max-width: $max-sm) {
        font-size: 18px;
      }

      @media (max-width: $max-xs) {
        font-size: 16px;
      }
    }
  }
}
</style>
