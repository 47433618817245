<template>
  <section id="concept" class="our-views">
    <div class="container">
      <div class="section-head">
        <h2 class="section-head__ttl animate__animated animate__fadeIn">
          Наші погляди
        </h2>
      </div>
      <div class="our-views__content">
        <div class="our-views__content-ttl">
          Ютуб канал - це потужний маркетинговий інструмент, який:
        </div>
        <ul>
          <li class="animate__animated animate__fadeInLeft">
            Робить Вас і Ваш бренд відомим
          </li>
          <li>
            Підвищує довіру до бренду
          </li>
          <li class="animate__animated animate__fadeInLeft">
            Надає значну конкурентну перевагу в вашій ніші
          </li>
          <li>
            Генерує потік звернень у Відділ продаж
          </li>
        </ul>
      </div>
      <div class="our-views__bottom">
        <div class="our-views__bottom-video">
          <div class="video-wrap">
            <div class="video-wrap-helper">
              <video muted autoplay loop :poster="require(`@/assets/image/our-views-bg.jpg`)">
                <source :src="`${baseUrl}video/shouril.webm`" type="video/webm">
                <source :src="`${baseUrl}video/shouril.mp4`" type="video/mp4">
              </video>
            </div>
          </div>
        </div>
        <div class="our-views__bottom-text">
          <p>
            Ми любимо та поважаємо ютуб, як інструмент лідогенерації. <br>
            Крім того, треба ще пошукати завзятіших шанувальників цієї платформи, ніж команда <br>
            <strong>OCTOPUS</strong>
          </p>
          <p>
            Якщо у вас теж більше мільйона підписок на всілякі ютуб канали, <br>
            то ви маєте розуміти наше обурення, коли вся увага переметнулась на SMM
          </p>
          <p>
            Як спеціалісти в маркетингових стратегіях, вважаємо, <br> що маркетинг має охоплювати всі
            можливі інструменти: <br> від грамотно побудованого сайту і до соціальних мереж
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OurViews',
  data() {
    return {
      baseUrl: process.env.BASE_URL,
    }
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/vars';

.our-views {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 90px;
  z-index: 2;

  @media (max-width: $max-lg) {
    padding-top: 50px;
  }

  .container {
    width: 100%;
    padding: 0 15px;
  }

  &__content {
    margin-top: 64px;
    padding: 0 25px;

    @media (max-width: $max-xl) {
      margin-top: 40px;
    }

    @media (max-width: $max-md) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 30px;
    }

    @media (max-width: $max-sm) {
      margin-top: 20px;
    }

    @media (max-width: $max-xs) {
      max-width: 290px;
      margin: 15px auto 0;
      padding: 0;
    }

    &-ttl {
      font-weight: 600;
      font-size: 28px;

      @media (max-width: $max-xxl) {
        font-size: 24px;
      }

      @media (max-width: $max-md) {
        font-size: 22px;
      }

      @media (max-width: $max-sm) {
        font-size: 16px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 24px 0 0;
      list-style-type: none;

      li {
        position: relative;
        flex-basis: 50%;
        margin-top: 16px;
        font-weight: 500;
        font-size: 21px;

        @media (max-width: $max-xxl) {
          font-size: 16px;
        }

        @media (max-width: $max-lg) {
          flex-basis: 100%;
        }

        @media (max-width: $max-sm) {
          font-size: 13px;
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: $accent;
          filter: blur(4px);
          transform: translateY(-50%);
          transition: all 0.4s ease-out;

          @media (max-width: $max-sm) {
            width: 12px;
            height: 12px;
            filter: blur(3px);
          }
        }

        &:nth-child(odd) {
          @media (min-width: $min-lg) {
            padding-right: 58px;
            text-align: right;

            &::after {
              right: 24px;
            }
          }

          @media (max-width: $max-lg) {
            padding-left: 58px;
            text-align: left;

            &::after {
              left: 24px;
            }
          }

          @media (max-width: $max-sm) {
            padding-left: 28px;

            &::after {
              left: 0;
            }
          }
        }

        &:nth-child(even) {
          padding-left: 58px;
          text-align: left;

          &::after {
            left: 24px;
          }

          @media (max-width: $max-sm) {
            padding-left: 28px;

            &::after {
              left: 0;
            }
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    min-height: 464px;
    margin-top: 58px;

    @media (max-width: $max-lg) {
      flex-direction: column;
      min-height: auto;
    }

    &-video {
      width: 40%;


      @media (max-width: $max-lg) {
        width: calc(100% + 30px);
      }

      .video-wrap {
        position: relative;
        top: 0;
        left: -15px;
        width: calc(100% + 192px);
        height: 100%;

        @media screen and (min-width: 1921px) {
          left: 0;
        }

        @media (max-width: $max-lg) {
          width: 100%;
          left: 0;
        }

        &-helper {
          position: relative;
          height: 0;
          padding-bottom: 56.25%;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(18, 23, 34, 0) 62.84%, #121722 100%);
            z-index: 2;

            @media (max-width: $max-lg) {
              background: linear-gradient(180deg, rgba(18, 23, 34, 0) 0%, #121722 100%);
            }
          }

          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
          }
        }
      }
    }

    &-text {
      position: relative;
      width: 60%;
      font-weight: 600;
      font-size: 21px;
      line-height: 1.2;
      text-align: right;
      z-index: 2;

      @media (max-width: $max-xxl) {
        font-size: 18px;
      }

      @media (max-width: $max-lg) {
        width: 80%;
        margin-top: -170px;
        font-size: 16px;
        text-align: center;
      }

      @media (max-width: $max-md) {
        width: 100%;
        margin-top: -100px;
        padding: 0 10px;
        font-size: 12px;
      }

      p {
        margin: 25px 0;

        @media (max-width: $max-lg) {
          margin: 18px 0;
        }
      }

      strong {
        display: inline-block;
        margin-top: 10px;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        background: linear-gradient(
                -45deg,
                $accent,
                $hover,
                $blue

        );
        background-size: 400% 400%;
        animation: gradient 6s ease infinite;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width: $max-lg) {
          font-size: 32px;
        }

        @media (max-width: $max-md) {
          font-size: 26px;
        }
      }
    }
  }
}
</style>
