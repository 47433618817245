<script>
export default {
  methods: {
    getImageUrlForWebpPlugin(data) {
      return Array.isArray(data) && data.length > 0 ?
          data.map(url => require(`@/assets/image/portfolio/${url}`)) :
          [require(`@/assets/image/portfolio/${data}`)]
    },
  },
}
</script>
