export const youtube = [
  {
    id: 1,
    title: 'Маркетингове дослідження',
    text: 'Проводимо маркетингове дослідження ніші: аналіз конкурентів та їх взаємодію з Digital каналами. Створюємо стратегію, даємо рекомендації.',
  },
  {
    id: 2,
    title: 'Створення контент-плану',
    text: 'Розробляємо стратегію входу в соціальні медіа.',
  },
  {
    id: 3,
    title: 'Детальний сценарій',
    text: 'Прописуємо ключові тези, які необхідно розкрити в ролику, щоб зачепити цільову аудиторію.',
  },
  {
    id: 4,
    title: 'Знімання та монтаж відео',
    text: 'Генеруємо та реалізовуємо ідеї на базі стратегії.',
  },
  {
    id: 5,
    title: 'SEO оптимізація ютуб-контенту',
    text: 'Підбір ключових слів за допомогою яких ваші відео почнуть з’являтись в рекомендаціях на YouTube.',
  },
  {
    id: 6,
    title: 'Розробка індивідуальних тегів',
    text: 'Пошук спеціальних хештегів для соціальних мереж з ціллю органічно підвищити охоплення.',
  },
  {
    id: 7,
    title: 'Налаштування контекстної та таргетованої реклами',
    text: 'Розробка креативів, написання текстів для рекламних оголошень.',
  },
  {
    id: 8,
    title: 'Розробка семантичного ядра для YouTube каналу',
    text: 'Пошук релевантних запитів та ключових слів для просування вашого бізнесу.',
  },
  {
    id: 9,
    title: 'Робота з медійним обличчям',
    text: 'Проводимо майстер-класи, як триматись в кадрі. Підбираємо одяг та макіяж.',
  },
  {
    id: 10,
    title: 'Взаємодія з аудиторією',
    text: 'Ком\'юніті менеджмент, спілкування з авдиторією, відповідь на коментарі.',
  },
]
