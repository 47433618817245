<template>
  <header class="header">
    <div class="container-fluid">
      <div>
        <div class="logo-wrap">
          <a href="/" class="logo">
            <img :src="require(`@/assets/image/${innerWidth > 991 ? 'logo-text.svg' : 'logo-mob.svg'}`)" alt="Octopus">
          </a>
        </div>
        <nav class="header__menu main-menu">
          <div
              v-if="innerWidth < 992"
              :class="['toggle_mnu menu-button-container', { open: isOpenedMainMenu }]"
              @click="toggleMenu"
          >
            <div v-if="!isOpenedMainMenu" class="menu-open">
              Меню
            </div>
            <div v-else class="menu-close"></div>
          </div>
          <div v-if="innerWidth > 991" :class="['main-menu-container', { open: isOpenedMainMenu }]">
            <ul class="main-menu-wrapper">
              <li class="menu-item current-menu-item active">
                <a class="menu-item__link" href="#" @click.prevent="scrollToId('concept')">Концепція</a>
              </li>
              <li class="menu-item">
                <a class="menu-item__link" href="#" @click.prevent="scrollToId('algorithm')">Алгоритм співпраці</a>
              </li>
              <li class="menu-item">
                <a class="menu-item__link" href="#" @click.prevent="scrollToId('services')">Послуги</a>
              </li>
              <li class="menu-item">
                <a class="menu-item__link" href="#" @click.prevent="scrollToId('portfolio')">Портфоліо</a>
              </li>
              <li class="menu-item">
                <a class="menu-item__link" href="#" @click.prevent="scrollToId('meeting')">Зустріч</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div v-if="innerWidth < 992" :class="['main-menu-container', { open: isOpenedMainMenu }]">
        <ul class="main-menu-wrapper">
          <li class="menu-item current-menu-item active">
            <a class="menu-item__link" href="#" @click.prevent="scrollToId('concept')">Концепція</a>
          </li>
          <li class="menu-item">
            <a class="menu-item__link" href="#" @click.prevent="scrollToId('algoritm')">Алгоритм співпраці</a>
          </li>
          <li class="menu-item">
            <a class="menu-item__link" href="#" @click.prevent="scrollToId('services')">Послуги</a>
          </li>
          <li class="menu-item">
            <a class="menu-item__link" href="#" @click.prevent="scrollToId('portfolio')">Портфоліо</a>
          </li>
          <li class="menu-item">
            <a class="menu-item__link" href="#" @click.prevent="scrollToId('meeting')">Зустріч</a>
          </li>
<!--          <li>-->
<!--            <ul class="translation translation-mob">-->
<!--              <li class="translation__item item-language-eng active">-->
<!--                <a href="#" class="translation__link">eng</a>-->
<!--              </li>-->
<!--              <li class="translation__item item-language-ukr">-->
<!--                <a href="#" class="translation__link">ukr</a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </li>-->
        </ul>
      </div>
      <div class="header__phone">
        <template v-if="innerWidth > 991">
          <div class="phone">
            <a href="tel:+380931927057" class="phone__link">+38 093 192 70 57</a>
          </div>
        </template>
        <form class="callback form-callback" @submit.prevent="submit">
          <template v-if="!isOpenedCallbackForm">
            <div class="callback__btn" @click="isOpenedCallbackForm = true">
              <span>
                <template v-if="innerWidth > 991">Замовити дзвінок</template>
              </span>
            </div>
          </template>
          <template v-else>
            <button v-if="innerWidth > 992" class="callback__btn" type="submit">
              Замовити
            </button>
          </template>

          <div :class="['callback__input-wrap', { show: isOpenedCallbackForm }]">
            <template v-if="innerWidth < 992">
              <div class="phone">
                <a href="tel:+380931927057" class="phone__link">+38 093 192 70 57</a>
              </div>
            </template>
            <div class="callback__input-wrap-helper">
              <label for="header-phone">введіть ваш номер</label>
              <masked-input
                  v-model="formData.phone"
                  id="header-phone"
                  mask="\+38 011 111 11 11"
                  placeholder="+38 093 192 70 57"
                  type="tel"
                  name="phone"
                  required
              ></masked-input>
            </div>
            <template v-if="innerWidth < 992">
              <button
                  class="callback__button"
                  type="submit"
                  @click="isOpenedCallbackForm = true"
              >
                <span>Замовити дзвінок</span>
              </button>
            </template>
            <div class="callback__close" @click="isOpenedCallbackForm = false"></div>
          </div>
        </form>
      </div>
<!--      <template v-if="innerWidth > 991">-->
<!--        <ul class="translation">-->
<!--          <li class="translation__item item-language-eng active">-->
<!--            <a href="#" class="translation__link">-->
<!--              <span>e</span>-->
<!--              <span>n</span>-->
<!--              <span>g</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="translation__item item-language-ukr">-->
<!--            <a href="#" class="translation__link">-->
<!--              <span>u</span>-->
<!--              <span>k</span>-->
<!--              <span>r</span>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </template>-->
    </div>
  </header>
</template>

<script>
import SubmitForm from '@/mixins/SubmitForm'

export default {
  name: 'Header',
  mixins: [SubmitForm],
  props: {
    innerWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpenedMainMenu: false,
      isOpenedCallbackForm: false,
      formData: {
        subject: 'Замовити дзвінок',
        phone: '',
      },
    }
  },
  methods: {
    toggleMenu() {
      this.isOpenedMainMenu = !this.isOpenedMainMenu
    },
    scrollToId(el) {
      this.isOpenedMainMenu = false

      document.getElementById(el).scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/header';
</style>
