import VueRouter from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import ThankYouPage from "@/pages/ThankYouPage.vue";

const routes = [
  { path: "", component: HomePage },
  { path: "/thank-you", component: ThankYouPage }
];

export const router = new VueRouter({
  mode: "history",
  routes
});
