<template>
  <div id="portfolioDetailDialog" style="display: none">
    <template v-if="item.title">
<!--      <div class="order-popup order-popup_main order-popup_imageblock" >-->
      <div class="order-popup order-popup_main">
        <div
          class="img"
          v-webp:bgs="getImageUrlForWebpPlugin(item.imageUrl)"
        ></div>
        <div class="order-popup__wrap order-popup_main__wrap">
          <div class="order-popup__descr">
            <h2>
              {{ item.title }}
            </h2>
            <div class="order-popup__text" v-html="item.description"></div>
          </div>
          <span
              class="video-link"
              @click="$emit('show-youtube-dialog', item)"
          ></span>
          <div class="order-popup__price">
            <div class="order-popup__price-budget">Бюджет: <span>{{ item.price }}</span></div>
            <div class="order-popup__price-term">Термін: <span>{{ item.term }}</span></div>
          </div>
          <button
              class="btn btn_h_60 btn_green"
              @click="$emit('show-callback-dialog', item)"
          >
            Замовити
          </button>
<!--          <div class="imageblock">-->
<!--            <div class="imageblock-helper">-->
<!--              <img src="../assets/image/portfolio/img-13.jpg" alt="" class="image">-->
<!--              <div class="imageblock__btn">Фоторепортаж</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import GetImageUrlForWebpPlugin from '@/mixins/GetImageUrlForWebpPlugin'

export default {
  name: 'PortfolioDetailDialog',
  mixins: [GetImageUrlForWebpPlugin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/vars';

.fancybox__backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
  filter: blur(10px) !important;
}

.fancybox__content > .carousel__button.is-close {
  top: 55px !important;
  right: 63px !important;
}

.fancybox__content {
  width: 90%;
  max-width: 1600px;
  padding: 0;
  color: $white;
  background-color: transparent;

  .order-popup {
    position: relative;
    width: 100%;
    border-radius: 65px;

    @media (max-width: $max-lg) {
      border-radius: 30px;
    }

    @media (max-width: $max-xs) {
      border-radius: 20px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 3;
    }

    &::before {
      height: 90%;
      top: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 33.85%, rgba(0, 0, 0, 0) 100%);
    }

    &::after {
      height: 20%;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      filter: blur(20px);
      border-radius: 65px 65px 0 0;
      transform: rotate(-180deg);

      @media (max-width: $max-lg) {
        border-radius: 50px 50px 0 0;
      }

      @media (max-width: $max-xs) {
        border-radius: 30px 30px 0 0;
      }
    }

    &_main {
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 65px;
      overflow: hidden;

      @media (max-width: $max-lg) {
        border-radius: 30px;
      }

      @media (max-width: $max-xs) {
        border-radius: 20px;
      }

      &__wrap {
        padding: 90px 140px 290px;

        @media (max-width: 1699px) {
          padding: 90px 120px 230px;
        }

        @media (max-width: $max-xxl) {
          padding: 60px 80px 230px;
        }

        @media (max-width: $max-lg) {
          align-items: center;
          padding: 40px 50px;
        }

        @media (max-width: $max-sm) {
          padding: 46px 20px 26px;
        }
      }
    }

    .img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(15px);
    }

    &__price {
      position: absolute;
      left: 140px;
      bottom: 70px;
      font-size: 21px;
      font-weight: 500;
      z-index: 10;

      @media (max-width: $max-xxl) {
        left: 80px;
        bottom: 45px;
        font-size: 18px;
      }

      @media (max-width: $max-lg) {
        position: static;
        margin: 0 0 30px;
      }

      @media (max-width: $max-md) {
        font-size: 16px;
      }

      @media (max-width: $max-sm) {
        font-size: 14px;
      }

      @media (max-width: $max-xs) {
        font-size: 12px;
      }

      @media (max-width: 374px) {
        font-size: 11px;
      }

      span {
        font-weight: 600;
      }
    }

    .btn {
      position: absolute;
      right: 140px;
      bottom: 60px;
      z-index: 10;

      @media (max-width: $max-xxl) {
        right: 80px;
        bottom: 40px;
      }

      @media (max-width: $max-lg) {
        position: static;
      }
    }

    .video-link {
      width: 140px;
      height: 83px;
      z-index: 10;

      @media (max-width: $max-xl) {
        width: 60px;
        height: 80px;
      }

      @media (min-width: $min-lg) {
        position: absolute;
        bottom: 110px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .imageblock {
      position: absolute;
      width: 529px;
      height: 100%;
      top: 0;
      right: 0;
      border-radius: 0 65px 65px 0;
      z-index: 5;
      overflow: hidden;

      &-helper {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .image {
        height: 100%;
      }

      &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25.5px;
        transform: translateX(-50%);
        width: 187px;
        height: 51px;
        font-size: 18px;
        font-weight: 500;
        color: $white;
        background: rgba(0, 0, 0, 0.55);
        border: 1px solid $white;
        z-index: 9;
        cursor: pointer;
      }
    }

    &__wrap {
      width: 100%;
      height: 100%;
      z-index: 10;

      @media (max-width: $max-lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      h2 {
        max-width: 736px;
        width: 100%;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.2;

        @media (max-width: $max-xxl) {
          font-size: 24px;
        }

        @media (max-width: $max-lg) {
          padding: 0 80px;
          font-size: 22px;
        }

        @media (max-width: $max-md) {
          font-size: 19px;
          padding: 0 50px;
        }

        @media (max-width: $max-sm) {
          font-size: 17px;
          padding: 0;
        }

        @media (max-width: $max-xs) {
          font-size: 15px;
        }

        @media (max-width: 374px) {
          font-size: 13px;
        }
      }
    }

    &__text {
      max-width: 1284px;
      max-height: 286px;
      margin-top: 45px;
      font-size: 21px;
      font-weight: 500;
      line-height: 1.2;
      //overflow-y: scroll;

      @media (max-width: 1699px) {
        br {
          display: none;
        }
      }

      @media (max-width: $max-xxl) {
        margin-top: 30px;
        font-size: 18px;
      }

      @media (max-width: $max-lg) {
        margin-top: 25px;
        padding: 0;
        font-size: 17px;
      }

      @media (max-width: $max-md) {
        font-size: 15px;
      }

      @media (max-width: $max-sm) {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
      }

      @media (max-width: $max-xs) {
        margin-top: 15px;
        font-size: 11px;
      }
    }

    &__descr {
      position: relative;
      max-height: 380px;
      z-index: 10;
      overflow: hidden;

      @media (max-width: 1699px) {
        max-height: 340px;
      }

      @media (max-width: $max-xl) {
        max-height: 250px;
      }

      @media (max-width: $max-lg) {
        max-height: 100%;
        margin-bottom: 30px;
        overflow: visible;
      }
    }

    &_imageblock {
      min-height: 800px;

      .order-popup__wrap {
        padding: 90px 530px 230px 140px;
      }
    }
  }
}
</style>
